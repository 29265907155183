/* eslint-disable react-hooks/exhaustive-deps */
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import React, { useState, useCallback, useEffect } from 'react';
import { FiSearch, FiCheckCircle } from 'react-icons/fi';
// import { useHistory } from 'react-router-dom';

import DraggableDialog from '../../../../components/DialogApproveNotification';
import Layout from '../../../../components/Layout';
import { Table } from '../../../../components/Ui';
import { useLoading } from '../../../../hooks/Loading';
import { useToast } from '../../../../hooks/Toast';
import { useLoadingTopBar } from '../../../../hooks/TopLoadingBar';
import api from '../../../../services/api';
import { Search, TableHeader } from './styles';

type ICustomer = {
  name: string;
};

type ICarrier = {
  name: string;
};

type IProduct = {
  name: string;
};

type IBatch = {
  id: string;
  delivery_customer: ICustomer;
  product: IProduct;
  weight: number;
};

type IRequest = {
  id: string;
  batch: IBatch;
  amount: number;
  carrier: ICarrier;
  created_at: Date;
};

const Offers: React.FC = () => {
  const [openDialogApprove, setOpenDialogApprove] = useState(false);
  const [selectedId, setSelectedId] = useState<string | number>();
  const [data, setData] = useState<[]>([]);
  const { addToast } = useToast();
  const { complete, staticStart } = useLoadingTopBar();
  const { setLoading } = useLoading();
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [total, setTotal] = useState(0);
  const [input, setInput] = useState('');

  /**
   * Get data to api
   */
  const getDataToApi = useCallback(async () => {
    setLoading(true);
    const response = await api.get(
      `/batches/notifications/answered?page=${page}&limit=${rowsPerPage}${
        input ? `&search=${input}` : ''
      }`,
    );

    setTotal(response.data.total);
    setRowsPerPage(response.data.per_page);
    setPage(response.data.current_page);
    setData(
      response.data.data.map((notification: IRequest) => {
        return {
          id: notification.id,
          carrier: notification.carrier.name,
          customer: notification.batch.delivery_customer.name,
          weight: Number(notification.batch.weight).toLocaleString('pt-BR'),
          amount: `R$ ${new Intl.NumberFormat().format(notification.amount)}`,
          created_at: new Date(notification.created_at).toLocaleDateString(),
        };
      }),
    );
    setLoading(false);
  }, [input, rowsPerPage, page]);

  /**
   * Reload data pages and rows per page
   */
  useEffect(() => {
    const timeoutId = setTimeout(() => getDataToApi(), 200);
    return () => clearTimeout(timeoutId);
  }, [rowsPerPage, page]);

  /**
   * Reload data with input
   */
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (data.length > 0) {
      const timeoutId = setTimeout(() => getDataToApi(), 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [input]);

  /**
   * Set Input Change
   */
  const handleChangeSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setInput(value);

      if (value === '') {
        setTimeout(() => getDataToApi(), 1000);
      }
    },
    [],
  );

  /**
   * Set rows per page
   */
  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const number = event.target.value as unknown as number;
      setRowsPerPage(number);
    },
    [],
  );

  /**
   * Set page change
   */
  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage + 1);
  }, []);

  const handleApproveCarrier = useCallback(async () => {
    staticStart();

    try {
      await api.post(`/batches/notifications/approve/${selectedId}`);

      complete();
      addToast({
        title: 'Transportadora aprovada com sucesso!',
        type: 'success',
      });
      getDataToApi();

      setOpenDialogApprove(false);
    } catch (err) {
      complete();
      addToast({
        title: 'Não foi possivel aprovar a transportadora!',
        type: 'error',
      });
    }
  }, [selectedId]);

  const handleRejectCarrier = useCallback(async () => {
    staticStart();

    try {
      await api.post(`/batches/notifications/reject/${selectedId}`);

      complete();
      addToast({
        title: 'Transportadora rejeitada com sucesso!',
        type: 'success',
      });
      getDataToApi();

      setOpenDialogApprove(false);
    } catch (err) {
      complete();
      addToast({
        title: 'Não foi possivel rejeitar a transportadora!',
        type: 'error',
      });
    }
  }, [selectedId]);

  return (
    <Layout>
      <div className="container mx-auto">
        <h2 className="text-2xl text-opaque mt-2 mb-5">Aprovação de Lotes</h2>

        <div className="rounded-sm shadow-md px-5 py-5 bg-white mt-5">
          <div className="flex px-2 w-full flex-row">
            <Search>
              <FiSearch size={20} />
              <input
                type="text"
                name="search"
                placeholder="Filtre por nome da transportadora, nome do cliente e cnpj"
                value={input}
                onChange={handleChangeSearch}
              />
            </Search>
          </div>
        </div>

        <div className="rounded-sm shadow-md px-5 py-5 bg-white mt-5">
          <TableHeader className="px-4">
            <span className="text-lg font-normal">
              Retorno da oferta dos lotes
            </span>
          </TableHeader>
          <Table
            title="Listagem de Solicitações"
            columns={[
              'Transportadora',
              'Cliente',
              'Quantidade',
              'Valor',
              'Data',
            ]}
            page={page}
            rowsPerPage={rowsPerPage}
            total={total}
            setPage={handleChangePage}
            setRowsPerPage={handleChangeRowsPerPage}
            data={data}
            editable={({ id }) => (
              <>
                <Tooltip title="Aprovar Oferta" placement="top">
                  <IconButton
                    onClick={() => {
                      setSelectedId(id);
                      setOpenDialogApprove(true);
                    }}
                  >
                    <FiCheckCircle />
                  </IconButton>
                </Tooltip>
              </>
            )}
          />
        </div>
        <DraggableDialog
          title="Atenção"
          description="Deseja realmente aprovar está oferta?"
          titleAcceptButton="Confirmar"
          titleRejectButton="Rejeitar"
          open={openDialogApprove}
          close={() => setOpenDialogApprove(false)}
          accept={() => handleApproveCarrier()}
          reject={() => handleRejectCarrier()}
        />
      </div>
    </Layout>
  );
};

export default Offers;
