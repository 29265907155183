import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import Batches from '../pages/Admin/Batches';
import ApproveBatches from '../pages/Admin/Batches/ApproveBatches';
import EditBatches from '../pages/Admin/Batches/Edit';
import NewBatches from '../pages/Admin/Batches/New';
import ShowBatches from '../pages/Admin/Batches/Show';
import Branches from '../pages/Admin/Branches';
import EditBranches from '../pages/Admin/Branches/Edit';
import NewBranches from '../pages/Admin/Branches/New';
import Carriers from '../pages/Admin/Carriers';
import EditCarriers from '../pages/Admin/Carriers/Edit';
import NewCarriers from '../pages/Admin/Carriers/New';
import ShowCarriers from '../pages/Admin/Carriers/Show';
import Customers from '../pages/Admin/Customers';
import Localization from '../pages/Admin/Localization';
import Products from '../pages/Admin/Products';
import ShowProducts from '../pages/Admin/Products/Show';
import PrivacyPolicy from '../pages/Admin/Settings/PrivacyPolicy';
import TransportOrders from '../pages/Admin/TransportOrders';
import ExchangeNFe from '../pages/Admin/TransportOrders/ExchangeNFe';
import InformNFe from '../pages/Admin/TransportOrders/InformNFe';
import Users from '../pages/Admin/Users';
import EditUsers from '../pages/Admin/Users/Edit';
import NewUsers from '../pages/Admin/Users/New';
import Route from './Route';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/users" exact component={Users} isPrivate />
      <Route path="/users/edit/:id" exact component={EditUsers} isPrivate />
      <Route path="/users/new" exact component={NewUsers} isPrivate />

      <Route path="/carriers" exact component={Carriers} isPrivate />
      <Route
        path="/carriers/show/:id"
        exact
        component={ShowCarriers}
        isPrivate
      />
      <Route path="/carriers/new" exact component={NewCarriers} isPrivate />
      <Route
        path="/carriers/edit/:id"
        exact
        component={EditCarriers}
        isPrivate
      />

      <Route path="/products" exact component={Products} isPrivate />
      <Route
        path="/products/show/:id"
        exact
        component={ShowProducts}
        isPrivate
      />

      <Route path="/customers" exact component={Customers} isPrivate />

      <Route path="/branches" exact component={Branches} isPrivate />
      <Route path="/branches/new" exact component={NewBranches} isPrivate />
      <Route
        path="/branches/edit/:id"
        exact
        component={EditBranches}
        isPrivate
      />

      <Route path="/batches" exact component={Batches} isPrivate />
      <Route path="/batches/new" exact component={NewBatches} isPrivate />
      <Route path="/batches/edit/:id" exact component={EditBatches} isPrivate />
      <Route path="/batches/show/:id" exact component={ShowBatches} isPrivate />
      <Route
        path="/batches/approve"
        exact
        component={ApproveBatches}
        isPrivate
      />

      <Route
        path="/transport-orders"
        exact
        component={TransportOrders}
        isPrivate
      />

      <Route
        path="/transport-orders/inform-nfe"
        exact
        component={InformNFe}
        isPrivate
      />

      <Route
        path="/transport-orders/exchange-nfe"
        exact
        component={ExchangeNFe}
        isPrivate
      />

      <Route path="/localization" exact component={Localization} isPrivate />

      <Route path="/privacy-policy" exact component={PrivacyPolicy} isPrivate />
      <Redirect from="*" to="/" />
    </Switch>
  );
};

export default Routes;
