import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

type ContainerProps = {
  active: boolean;
};

export const Container = styled.nav<ContainerProps>`
  grid-area: sidebar;
  display: flex;
  width: 280px;
  height: 100%;
  background-color: ${props => props.theme.colors.primary};

  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  transition: all 200ms ease-in;

  z-index: 9999;

  @media (min-width: 0) and (max-width: 991.98px) {
    visibility: hidden;
    position: fixed;
    transition: all 200ms ease-out;
    width: 0;

    ${props =>
      props.active &&
      css`
        position: fixed;
        visibility: visible;
        width: 280px;
        z-index: 999;
      `}
  }

  ${props =>
    !props.active &&
    css`
      width: 0px;
      visibility: hidden;
      transform: translateX(-280px);
    `}
`;

export const LogoContent = styled.div`
  display: flex;
  width: 280px;
  height: 70px;
  object-fit: cover;
  padding: 10px 30px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  position: relative;

  &::before {
    content: '';
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    height: 2px;
    width: 90%;
    margin: 0 auto;
    position: absolute;
    bottom: 0;
    z-index: 999;
  }
`;

export const Image = styled.img`
  width: 70%;
`;

export const Menu = styled.ul`
  width: 100%;
  height: auto;
  padding: 10px 0px;

  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px; /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: transparent; /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${props =>
      props.theme.colors.opaque}; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 3px solid transparent; /* creates padding around scroll thumb */
  }
`;

export const ContainerMenuItem = styled.li`
  margin-bottom: 15px;
`;

interface MenuItemProps {
  active: boolean;
}

export const ItemLink = styled.a<MenuItemProps>`
  display: flex;
  padding: 15px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: all 200ms ease-in;
  align-items: center;

  color: ${props => props.theme.colors.white};
  font-weight: 400;
  position: relative;

  svg {
    margin-right: 15px;
  }

  span {
    svg {
      display: flex;
      height: 100%;
      align-items: center;
      position: absolute;
      right: 0;
      top: 0;
      margin-right: 30px;
    }
  }

  &:hover {
    &::before {
      content: '';
      width: 3px;
      height: 100%;
      left: 0;
      position: absolute;
      background-color: ${props => props.theme.colors.white};
      border-radius: 10px;
      transition: all 500ms ease-in;
    }
  }

  ${props =>
    props.active &&
    css`
      &::before {
        content: '';
        width: 3px;
        height: 100%;
        left: 0;
        position: absolute;
        background-color: ${props.theme.colors.white};
        border-radius: 10px;
      }
    `}
`;

export const DropdownLink = styled(Link)`
  height: 60px;
  padding-left: 25px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${props => props.theme.colors.white};
  position: relative;

  &:hover {
    /* background-color: rgba(255, 255, 255, 0.1); */
    background-color: rgba(0, 0, 0, 0.1);

    &::before {
      content: '';
      width: 3px;
      height: 100%;
      left: 0;
      position: absolute;
      background-color: ${props => props.theme.colors.white};
      border-radius: 10px;
      transition: all 500ms ease-in;
    }
  }
`;

export const SidebarLabel = styled.span`
  margin-left: 16px;
`;
