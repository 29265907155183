import React, { useState, useEffect, useCallback } from 'react';
import { MdMenu } from 'react-icons/md';

import UserAvatar from '../../../assets/avatar-person.svg';
import { useAuth } from '../../../hooks/Authentication';
import DropdownUser from '../../DropdownUser';
import { Container, UserContent, User, Avatar, ToggleButton } from './styles';

type HeaderProps = {
  setActiveSidebar: () => void;
};

const Header: React.FC<HeaderProps> = ({ setActiveSidebar }) => {
  const { user } = useAuth();
  const [dropDownVisible, setDropdownVisible] = useState<boolean>(false);
  const dropDownRef = React.useRef<HTMLDivElement>(null);

  const handleCloseDropDown = useCallback(event => {
    if (
      dropDownRef.current &&
      !dropDownRef.current.contains(event.target as Node)
    ) {
      setDropdownVisible(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleCloseDropDown, true);
    return () => {
      document.removeEventListener('click', handleCloseDropDown, true);
    };
  }, [handleCloseDropDown]);

  return (
    <Container>
      <ToggleButton onClick={() => setActiveSidebar()}>
        <MdMenu size={30} />
      </ToggleButton>
      <UserContent>
        {/* <Notifications active>
          <MdNotifications size={30} />
        </Notifications> */}
        <User>
          <Avatar onClick={() => setDropdownVisible(!dropDownVisible)}>
            <img
              src={user.avatar_url ? user.avatar_url : UserAvatar}
              alt="Usuario"
            />
            <DropdownUser ref={dropDownRef} visible={dropDownVisible} />
          </Avatar>
        </User>
      </UserContent>
    </Container>
  );
};

export default Header;
