/* eslint-disable react/no-danger */
import React, { useEffect, useCallback, useState } from 'react';

import api from '../../services/api';

const PrivacyPolicy: React.FC = () => {
  const [text, setText] = useState('');

  const handleGetText = useCallback(async () => {
    const response = await api.get('/privacy-policies');

    setText(response.data.html);
  }, []);

  useEffect(() => {
    handleGetText();
  }, [handleGetText]);

  return (
    <div className="container mx-auto w-full xl:max-w-7xl 2xl:max-w-7xl py-4 sm:py-14 md:pt-8 md:pb-10 px-6 md:px-0">
      <h2 className="text-4xl text-opaque mt-2 mb-5">
        Política de Privacidade
      </h2>
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
};

export default PrivacyPolicy;
