import React from 'react';

import { useLoading } from '../../../hooks/Loading';
import Loading from '../../Loading';
import { Container } from './styles';

type ContainerProps = {
  setActiveHeader: () => void;
  headerActive: boolean;
};

const Content: React.FC<ContainerProps> = ({ setActiveHeader, children }) => {
  const { loading } = useLoading();

  return (
    <Container onClick={() => setActiveHeader()}>
      {loading ? <Loading /> : <>{children}</>}
    </Container>
  );
};

export default Content;
