import { FormHandles } from '@unform/core';
import React, { useCallback, useRef } from 'react';
import { FiLock, FiUser } from 'react-icons/fi';
import * as Yup from 'yup';

import pjson from '../../../package.json';
import Logo from '../../assets/logo-white.png';
import { Button, Input } from '../../components/Ui';
import { useAuth } from '../../hooks/Authentication';
import { useToast } from '../../hooks/Toast';
import getValidationErrors from '../../utils/getValidationErrors';
import { Content, Form } from './styles';

interface SignInFormData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const { signIn } = useAuth();

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .email('Digite um E-mail valido!')
            .required('Usuario Obrigatório'),
          password: Yup.string().required('Senha Obrigatória'),
        });

        await schema.validate(data, { abortEarly: false });

        await signIn({
          email: data.email,
          password: data.password,
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Confirme seus dados ou fale com um administrador!',
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [signIn],
  );
  return (
    <div className="w-full h-full md:h-full--header bg-white">
      <div className="flex flex-wrap flex-col md:flex-row h-full--header">
        <div className="w-full relative bg-banner-login hidden md:flex md:w-6/12 h-full bg-cover bg-no-repeat bg-bottom justify-center items-center px-4">
          <div className="flex flex-col mt-80 w-full items-center px-0 lg:px-10">
            <h1 className="text-white font-bold text-3xl text-center z-50">
              PRODUZINDO E ARMAZENANDO AS RIQUEZAS DO AGRONEGÓCIO
            </h1>
            <hr className="bg-white w-full mt-6 opacity-50 z-50" />
            <img className="mt-6" src={Logo} alt="Logo Bertuol" width="200" />
          </div>
        </div>
        <div className="flex w-full md:w-6/12  h-full justify-end pb-14 md:pb-0 md:justify-center items-center bg-banner-login bg-cover bg-bottom md:bg-light flex-col">
          <Content>
            <div className="mb-4">
              <p className="font-bold md:font-normal text-2xl text-white md:text-opaque">
                Acesse sua conta
              </p>
            </div>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Input
                name="email"
                icon={FiUser}
                type="text"
                placeholder="E-Mail"
              />
              <Input
                name="password"
                icon={FiLock}
                type="password"
                placeholder="Senha"
              />
              <Button
                type="submit"
                color="white"
                theme="primary"
                size="medium"
                className="w-full mt-3"
              >
                Entrar
              </Button>
            </Form>
          </Content>
          <p className="font-normal text-xs text-white md:text-opaque">
            {`Versão ${pjson.version}`}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
