import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, forwardRef, MutableRefObject } from 'react';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import * as Yup from 'yup';

import { useToast } from '../../hooks/Toast';
import { useLoadingTopBar } from '../../hooks/TopLoadingBar';
import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';
import { InputMask } from '../Ui';
import { Content } from './styles';

interface PasswordDataProps {
  amount: string;
}

interface AmountProps {
  id: string | number | undefined;
  close?: (data: boolean) => void;
}

const Amount: React.ForwardRefRenderFunction<FormHandles, AmountProps> = (
  { id, close },
  ref:
    | MutableRefObject<FormHandles | null>
    | ((instance: FormHandles | null) => void)
    | null,
) => {
  // const formRef = useRef<FormHandles>(ref);
  const { complete, staticStart } = useLoadingTopBar();
  const { addToast } = useToast();

  const formRef = ref as MutableRefObject<FormHandles>;

  const handleSubmit = useCallback(
    async (data: PasswordDataProps) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          amount: Yup.string().required('Você deve preencher o valor'),
        });

        await schema.validate(data, { abortEarly: false });

        const amount = String(data.amount).slice(3, 10);

        // eslint-disable-next-line no-param-reassign
        data.amount = amount.replace('.', '').replace(/,/g, '.');

        staticStart();
        await api.patch(`/batches/amount/${id}`, data);
        complete();
        if (close) {
          close(false);
        }

        addToast({
          type: 'success',
          title: 'Frete atualizado com sucesso',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef?.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro ao atulizar o frete!',
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id],
  );

  return (
    <Content>
      <Form onSubmit={handleSubmit} ref={formRef}>
        <div className="flex flex-wrap w-full flex-row">
          <div className="w-full p-2">
            <label className="font-bold ml-1">Valor do Frete</label>
            <InputMask
              type="text"
              name="amount"
              mask={createNumberMask({
                prefix: 'R$ ',
                thousandsSeparatorSymbol: '.',
                allowDecimal: true,
                decimalSymbol: ',',
                requireDecimal: false,
                integerLimit: 5,
              })}
            />
          </div>
        </div>
      </Form>
    </Content>
  );
};

export default forwardRef(Amount);
