/* eslint-disable react-hooks/exhaustive-deps */
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import React, { useState, useCallback, useEffect } from 'react';
import { FiSearch, FiTruck } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import Layout from '../../../../components/Layout';
import { Table } from '../../../../components/Ui';
import { useLoading } from '../../../../hooks/Loading';
import api from '../../../../services/api';
import { Search } from './styles';

type ICustomer = {
  name: string;
};

type IProduct = {
  name: string;
};

type IContract = {
  reference_id: string;
};

type IRequest = {
  id: string;
  contract: IContract;
  delivery_customer: ICustomer;
  product: IProduct;
  quantity: number;
  amount_freight: number;
  balance: number;
  created_at: Date;
};

const ApprovedPage: React.FC = () => {
  const [data, setData] = useState<[]>([]);
  const history = useHistory();
  const { setLoading } = useLoading();
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [total, setTotal] = useState(0);
  const [input, setInput] = useState('');

  /**
   * Get data to api
   */
  const getDataToApi = useCallback(async () => {
    setLoading(true);
    const response = await api.get(
      `/batches/carriers/approved?page=${page}&limit=${rowsPerPage}${
        input ? `&search=${input}` : ''
      }`,
    );

    setTotal(response.data.total);
    setRowsPerPage(response.data.per_page);
    setPage(response.data.current_page);
    setData(
      response.data.data.map((notification: IRequest) => {
        return {
          id: notification.id,
          customer: notification.delivery_customer.name,
          product: notification.product.name,
          balance: Number(notification.balance).toLocaleString('pt-BR'),
          amount: `R$ ${new Intl.NumberFormat().format(
            notification.amount_freight,
          )}`,
          created_at: new Date(notification.created_at).toLocaleDateString(),
        };
      }),
    );
    setLoading(false);
  }, [input, rowsPerPage, page]);

  /**
   * Reload data pages and rows per page
   */
  useEffect(() => {
    const timeoutId = setTimeout(() => getDataToApi(), 200);
    return () => clearTimeout(timeoutId);
  }, [rowsPerPage, page]);

  /**
   * Reload data with input
   */
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (data.length > 0) {
      const timeoutId = setTimeout(() => getDataToApi(), 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [input]);

  /**
   * Set Input Change
   */
  const handleChangeSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setInput(value);

      if (value === '') {
        setTimeout(() => getDataToApi(), 1000);
      }
    },
    [],
  );

  /**
   * Set rows per page
   */
  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const number = event.target.value as unknown as number;
      setRowsPerPage(number);
    },
    [],
  );

  /**
   * Set page change
   */
  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage + 1);
  }, []);

  return (
    <Layout>
      <div className="container mx-auto">
        <h2 className="text-2xl text-opaque mt-2 mb-5">
          Demandas de Fretes Aprovadas
        </h2>

        <div className="rounded-sm shadow-md px-5 py-5 bg-white mt-5">
          <div className="flex px-2 w-full flex-row">
            <Search>
              <FiSearch size={20} />
              <input
                type="text"
                name="search"
                placeholder="Filtre por produto e cliente"
                value={input}
                onChange={handleChangeSearch}
              />
            </Search>
          </div>
        </div>

        <div className="rounded-sm shadow-md px-5 py-5 bg-white mt-5">
          <span className="hidden md:flex text-lg w-full font-normal">
            Lotes Aprovados
          </span>
          <Table
            title="Listagem de Lotes Aprovados"
            columns={['Cliente', 'Produto', 'Disponível', 'Frete', 'Data']}
            page={page}
            rowsPerPage={rowsPerPage}
            total={total}
            setPage={handleChangePage}
            setRowsPerPage={handleChangeRowsPerPage}
            data={data}
            editable={({ id }) => (
              <>
                <Tooltip title="Criar Ordem de Carregamento" placement="top">
                  <IconButton
                    onClick={() => {
                      history.push(`/transport-orders/new/${id}`);
                    }}
                  >
                    <FiTruck />
                  </IconButton>
                </Tooltip>
              </>
            )}
          />
        </div>
      </div>
    </Layout>
  );
};

export default ApprovedPage;
