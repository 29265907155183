/* eslint-disable @typescript-eslint/no-explicit-any */
import { useField } from '@unform/core';
import React, { useEffect, useRef, useState } from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { OptionTypeBase, Props } from 'react-select';

import { Container, Error, Select, Popover } from './styles';

interface InputProps extends Props<OptionTypeBase> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  borderTheme?: 'rounded' | 'rounded-dark' | 'rounded-light';
  boxShadow?: boolean;
  selectedValue?: (value: any) => void;
}

type SelectProps = {
  state: ValueProps;
  select: SelectInputProps;
};

type SelectInputProps = {
  setValue({ value, label }: ValueProps): () => void;
  clearValue(): () => void;
};

type ValueProps = {
  value: string;
  label?: string;
};

const Input: React.FC<InputProps> = ({
  name,
  icon: Icon,
  borderTheme,
  selectedValue,
  boxShadow,
  ...rest
}) => {
  const selectRef = useRef<SelectProps>(null);
  const [isFocused, setIsFocused] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const [isPopover, setIsPopover] = useState(false);

  /**
   * Só Deus sabe como o codigo abaixo está rodando
   * FAVOR NÃO ALTERAR NADA!
   */
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: ref => {
        return ref.props.isMulti
          ? ref.state.value?.map((option: OptionTypeBase) => option.value) || []
          : ref.state.value?.value || '';
      },
      setValue: (ref, value) => {
        ref.select.setValue(value || null);
      },
      clearValue: ref => {
        ref.select.clearValue();
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldName, registerField, defaultValue, selectedValue, selectedValue]);

  return (
    <Container
      isErrored={!!error}
      isFocused={isFocused}
      borderTheme={borderTheme}
      boxShadow={boxShadow}
      onMouseEnter={() => (error ? setIsPopover(true) : false)}
      onMouseLeave={() => (error ? setIsPopover(false) : false)}
    >
      {Icon && <Icon size={20} />}
      <Select
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        ref={selectRef}
        isMulti
        className="select-container"
        classNamePrefix="react-select"
        placeholder="Selecione..."
        // onChange={(value: any) => handleSelectChange(value)}
        noOptionsMessage={() => 'Nenhuma opção encontrada!'}
        {...rest}
      />

      {error && (
        <Error>
          <FiAlertCircle color="#E96379" size={20} />
        </Error>
      )}
      {isPopover && error && <Popover>{error}</Popover>}
    </Container>
  );
};

export default Input;
