import React, { useState, useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Layout from '../../../../components/Layout';
import { Button } from '../../../../components/Ui';
import { useLoading } from '../../../../hooks/Loading';
import api from '../../../../services/api';

type IResponse = {
  name: string;
  company_name: string;
  email: string;
  cnpj: string;
  address: AddressProps;
};

type AddressProps = {
  state_id: string;
  city_id: string;
  tellphone: string;
  cellphone: string;
  public_place: string;
  neighborhood: string;
  street: string;
  complement: string;
  number: number;
  zip_code: string;
  latitude: string;
  longitude: string;
  city: City;
  state: State;
};

type State = {
  id: string;
  name: string;
  uf: string;
};

type City = {
  id: string;
  name: string;
  uf: string;
};

interface Params {
  id: string;
}

const ShowCarrier: React.FC = () => {
  const history = useHistory();
  const [data, setData] = useState<IResponse>();
  const { id } = useParams<Params>();
  const { setLoading } = useLoading();

  const getDataToApi = useCallback(async () => {
    setLoading(true);
    const response = await api.get(`/carriers/${id}`);

    setData({
      ...response.data,
      address: response.data.carrier_address
        ? response.data.carrier_address.address
        : null,
    });

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getDataToApi();
    }, 200);
    return () => clearTimeout(timeoutId);
  }, [getDataToApi]);

  return (
    <Layout>
      <div className="container mx-auto">
        <h2 className="text-2xl text-opaque mt-2 mb-5">
          Informações da Transportadora
        </h2>

        <div className="rounded-md shadow-md px-5 py-5 bg-white mt-5">
          <div className="my-2 ml-3">
            <span className="text-lg font-bold text-opaque">
              Dados da Transportadora
            </span>
          </div>
          <hr className="mx-3" />
          <div className="flex flex-wrap w-full flex-row px-1">
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
              <span className="text-sm font-bold">Nome: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {data?.name}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
              <span className="text-sm font-bold">Razāo Social: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {data?.name}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
              <span className="text-sm font-bold">CNPJ: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {data?.cnpj}
              </span>
            </div>
          </div>
          <div className="my-2 ml-3">
            <span className="text-lg font-bold text-opaque">Contato</span>
          </div>
          <hr className="mx-3" />
          <div className="flex flex-wrap w-full flex-row px-1">
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
              <span className="text-sm font-bold">E-mail: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {data?.email}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
              <span className="text-sm font-bold">Telefone: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {data?.address?.tellphone}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
              <span className="text-sm font-bold">Celular: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {data?.address?.cellphone}
              </span>
            </div>
          </div>
          <div className="my-2 ml-3">
            <span className="text-lg font-bold text-opaque">Endereço</span>
          </div>
          <hr className="mx-3" />
          <div className="flex flex-wrap w-full flex-row px-1">
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-3/12 p-2">
              <span className="text-sm font-bold">Logradouro: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {data?.address?.public_place}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-3/12 p-2">
              <span className="text-sm font-bold">Endereço: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {data?.address?.street}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-3/12 p-2">
              <span className="text-sm font-bold">Numero: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {data?.address?.number}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-3/12 p-2">
              <span className="text-sm font-bold">Bairro: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {data?.address?.neighborhood}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-3/12 p-2">
              <span className="text-sm font-bold">Cidade: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {data?.address?.city?.name}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-3/12 p-2">
              <span className="text-sm font-bold">Estado: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {data?.address?.state?.name}
              </span>
            </div>
          </div>
          <div className="grid grid-cols-1 mt-5 px-2">
            <div className="flex justify-end">
              <div className="w-32">
                <Button
                  onClick={() => history.push('/carriers')}
                  color="opaque"
                  theme="dark"
                  size="medium"
                >
                  Voltar
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ShowCarrier;
