/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useRef } from 'react';
// import { FiLock } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import Layout from '../../../../components/Layout';
import { Button, Input, InputMask, Mask } from '../../../../components/Ui';
import { useToast } from '../../../../hooks/Toast';
import api from '../../../../services/api';
import getValidationErrors from '../../../../utils/getValidationErrors';

type FormData = {
  name: string;
  cpf: string;
  // rg: string;
  driver_license: string;
  // password: string;
  // password_confirmation: string;
};

const NewTrucker: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('O Nome Obrigatório'),
          cpf: Yup.string().required('O CPF é Obrigatório'),
          driver_license: Yup.string().required(
            'O Numero da Carteira de motorista é Obrigatório',
          ),
          phone: Yup.string().required('Telefone para contato é Obrigatório'),
          // password: Yup.string().min(
          //   6,
          //   'A senha deve ter no mínimo 6 caracteres',
          // ),
          // password_confirmation: Yup.string().oneOf(
          //   [Yup.ref('password'), undefined],
          //   'As Senhas não coincidem',
          // ),
        });

        await schema.validate(data, { abortEarly: false });

        await api.post(`/drivers`, data);
        history.push('/drivers');

        addToast({
          title: 'Motorista adicionado com sucesso!',
          type: 'success',
        });
      } catch (err: any) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        if (err.response.data.message === 'Trucker already exists') {
          addToast({
            type: 'error',
            title: 'O CPF cadastrado já existe no banco de dados.',
          });

          return;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro ao adicionar o Motorista.',
          description: err.response.data.message,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Layout>
      <div className="container mx-auto">
        <h2 className="text-2xl text-opaque mt-2 mb-5">Cadastrar Motorista</h2>

        <div className="rounded-md shadow-md px-5 py-5 bg-white mt-5">
          <div className="my-2 ml-3">
            <span className="text-lg font-bold text-opaque">
              Dados do Motorista
            </span>
          </div>
          <hr className="mx-3" />
          <Form ref={formRef} onSubmit={handleSubmit}>
            <div className="flex flex-wrap w-full flex-row">
              <div className="p-2 w-full md:w-4/12">
                <label className="font-bold ml-1">Nome</label>
                <Input type="text" name="name" />
              </div>
              <div className="p-2 w-full md:w-4/12">
                <label className="font-bold ml-1">CPF</label>
                <InputMask type="text" name="cpf" mask={Mask.CPF} />
              </div>
              <div className="p-2 w-full md:w-4/12">
                <label className="font-bold ml-1">CNH do Motorista</label>
                <Input type="text" name="driver_license" />
              </div>
              <div className="p-2 w-full md:w-4/12">
                <label className="font-bold ml-1">Telefone</label>
                <InputMask type="text" name="phone" mask={Mask.CELLPHONE} />
              </div>
            </div>

            <div className="grid grid-cols-1 mt-5 px-2">
              <div className="flex justify-end flex-col-reverse md:flex-row">
                <div className="w-32 md:mr-2 mt-2 md:mt-0">
                  <Button
                    theme="dark"
                    size="medium"
                    onClick={() => history.push('/drivers')}
                  >
                    Voltar
                  </Button>
                </div>
                <div className="w-32">
                  <Button
                    type="submit"
                    color="white"
                    theme="primary"
                    size="medium"
                  >
                    Salvar
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Layout>
  );
};

export default NewTrucker;
