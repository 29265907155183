/* eslint-disable react-hooks/exhaustive-deps */
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { FormHandles } from '@unform/core';
import React, { useState, useCallback, useEffect, createRef } from 'react';
import { FiEdit, FiX, FiTrash, FiLock, FiSearch, FiPlus } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import DraggableDialog from '../../../components/DraggableDialog';
import Layout from '../../../components/Layout';
import { Table, Button } from '../../../components/Ui';
import { useLoading } from '../../../hooks/Loading';
import { useToast } from '../../../hooks/Toast';
import { useLoadingTopBar } from '../../../hooks/TopLoadingBar';
import api from '../../../services/api';
import AlterPassword from './AlterPassword';
import { Search } from './styles';

type IRequest = {
  id: string;
  name: string;
  email: string;
  username: string;
  active: string;
  created_at: string;
  role: string;
};

const Users: React.FC = () => {
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [openDialogPassword, setOpenDialogPassword] = useState(false);
  const [selectedId, setSelectedId] = useState<string | number>();
  const [data, setData] = useState<IRequest[]>([]);
  const { complete, staticStart } = useLoadingTopBar();
  const { addToast } = useToast();
  const formRef = createRef<FormHandles>();
  const history = useHistory();
  const { setLoading } = useLoading();
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [total, setTotal] = useState(0);
  const [input, setInput] = useState('');

  /**
   * Get data to api
   */
  const getDataToApi = useCallback(async () => {
    setLoading(true);
    const response = await api.get(
      `/users?page=${page}&limit=${rowsPerPage}${
        input ? `&search=${input}` : ''
      }&role=developer, admin, user, carrier, logistics_user`,
    );

    setTotal(response.data.total);
    setRowsPerPage(response.data.per_page);
    setPage(response.data.current_page);
    setData(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      response.data.data.map((user: any) => {
        return {
          id: user.id,
          nome: user.name,
          email: user.email,
          active: user.active,
          role: user.role,
        };
      }),
    );
    setLoading(false);
  }, [input, rowsPerPage, page]);

  /**
   * Reload data pages and rows per page
   */
  useEffect(() => {
    const timeoutId = setTimeout(() => getDataToApi(), 200);
    return () => clearTimeout(timeoutId);
  }, [rowsPerPage, page]);

  /**
   * Reload data with input
   */
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (data.length > 0) {
      const timeoutId = setTimeout(() => getDataToApi(), 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [input]);

  /**
   * Set Input Change
   */
  const handleChangeSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setInput(value);

      if (value === '') {
        setTimeout(() => getDataToApi(), 1000);
      }
    },
    [],
  );

  /**
   * Set rows per page
   */
  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const number = event.target.value as unknown as number;
      setRowsPerPage(number);
    },
    [],
  );

  /**
   * Set page change
   */
  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage + 1);
  }, []);

  const handleSaveAlterPassword = () => {
    formRef.current?.submitForm();
  };

  /* Lida com a função de ativar/desativar usuários */
  const handleActiveUser = useCallback(async id => {
    staticStart();
    api
      .patch(`/users/active/${id}`)
      .then(() => {
        getDataToApi();
        complete();
        addToast({
          title: 'Usuário atualizado com sucesso!',
          type: 'success',
        });
      })
      .catch(() => {
        complete();
        addToast({
          title: 'Não foi possivel atualizar o usuário!',
          type: 'error',
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteUser = useCallback(async () => {
    try {
      staticStart();
      await api.delete(`/users/${selectedId}`);
      setOpenDialogDelete(false);

      complete();
      addToast({
        title: 'Usuário deletado com sucesso!',
        type: 'success',
      });
      getDataToApi();
    } catch (err) {
      complete();
      addToast({
        title: 'Não foi possivel atualizar o usuário!',
        type: 'error',
      });
    }
  }, [selectedId]);

  return (
    <Layout>
      <div className="container mx-auto">
        <div className="flex flex-row justify-between items-center px-2 mb-4 mt-2">
          <h2 className="text-2xl text-opaque">Usuários</h2>
        </div>

        <div className="rounded-sm shadow-md px-5 py-5 bg-white mt-5">
          <div className="flex px-2 w-full flex-row">
            <Search>
              <FiSearch size={20} />
              <input
                type="text"
                name="search"
                placeholder="Filtre por nome, usuário e email"
                value={input}
                onChange={handleChangeSearch}
              />
            </Search>
          </div>
        </div>

        <div className="rounded-md shadow-md px-5 py-5 bg-white mt-5">
          <div className="flex w-full items-center px-2 mb-2">
            <span className="hidden md:flex text-lg w-full font-normal">
              Listagem de usuários
            </span>
            <div className="flex w-full justify-end">
              <a href="/users/new">
                <Button type="submit" theme="primary" size="small">
                  <FiPlus size={25} />
                  Novo usuário
                </Button>
              </a>
            </div>
          </div>
          <Table
            columns={['Nome', 'E-mail', 'Ativo', 'Tipo']}
            page={page}
            rowsPerPage={rowsPerPage}
            total={total}
            setPage={handleChangePage}
            setRowsPerPage={handleChangeRowsPerPage}
            data={data}
            editable={({ id, active }) => (
              <div className="flex w-full">
                <Tooltip title="Alterar senha" placement="top">
                  <IconButton
                    onClick={() => {
                      setSelectedId(id);
                      setOpenDialogPassword(true);
                    }}
                  >
                    <FiLock />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Editar" placement="top">
                  <IconButton
                    onClick={() => {
                      history.push(`/users/edit/${id}`);
                    }}
                  >
                    <FiEdit />
                  </IconButton>
                </Tooltip>
                {!active && (
                  <Tooltip title="Acesso ao Sistema" placement="top">
                    <IconButton
                      onClick={() => {
                        handleActiveUser(id);
                      }}
                    >
                      <FiX className="error" />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Excluir" placement="top">
                  <IconButton
                    onClick={() => {
                      setSelectedId(id);
                      setOpenDialogDelete(true);
                    }}
                  >
                    <FiTrash />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          />
        </div>

        {/* Modal de alterar senha */}
        <DraggableDialog
          title="Atenção"
          description="Deseja realmente alterar a senha deste usuario?"
          open={openDialogPassword}
          titleAcceptButton="Salvar"
          titleCancelButton="Fechar"
          accept={handleSaveAlterPassword}
          cancel={() => setOpenDialogPassword(false)}
        >
          <AlterPassword
            ref={formRef}
            id={selectedId}
            close={setOpenDialogPassword}
          />
        </DraggableDialog>

        <DraggableDialog
          title="Atenção"
          description="Deseja realmente excluir este usuario?"
          titleAcceptButton="Confirmar"
          open={openDialogDelete}
          accept={() => handleDeleteUser()}
          cancel={() => setOpenDialogDelete(false)}
        />
      </div>
    </Layout>
  );
};

export default Users;
