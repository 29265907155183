/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { FiLock } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import Layout from '../../../../components/Layout';
import { Button, Input, Select } from '../../../../components/Ui';
import { useLoading } from '../../../../hooks/Loading';
import { useToast } from '../../../../hooks/Toast';
import api from '../../../../services/api';
import getValidationErrors from '../../../../utils/getValidationErrors';

type FormData = {
  id: string;
  name: string;
  email: string;
  role_id: string;
  password: string;
  password_confirmation?: string;
  branch_id?: string;
  carrier_id?: string;
};

type Roles = {
  id: string;
  name: string;
  description: string;
};

type SelectProps = {
  value: string;
  label: string;
  name?: string;
};

type Companies = {
  id: string;
  name: string;
  cnpj: string;
};

const NewUser: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [roles, setRoles] = useState<SelectProps[]>([]);
  const [branches, setBranches] = useState([]);
  const { setLoading } = useLoading();
  const history = useHistory();
  const { addToast } = useToast();

  const getRolesToApi = useCallback(async () => {
    setLoading(true);
    const response = await api.get('/roles');
    setRoles(
      response.data.data.map((role: Roles) => {
        return {
          value: role.id,
          label: role.description,
          name: role.name,
        };
      }),
    );
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBranches = useCallback(async () => {
    setLoading(true);
    const response = await api.get('/branches');
    setBranches(
      response.data.data.map((data: Companies) => {
        return {
          value: data.id,
          label: `${data.name} - ${data.cnpj}`,
        };
      }),
    );
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getBranches();
      getRolesToApi();
    }, 200);
    return () => clearTimeout(timeoutId);
  }, [getBranches, getRolesToApi]);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome Obrigatório'),
          email: Yup.string().required('E-mail Obrigatório'),
          role_id: Yup.string().required('O Tipo de Usuário é Obrigatório'),
          branch_id: data.branch_id
            ? Yup.string().required('A Empresa Obrigatória')
            : Yup.string(),
          // carrier_id: data.carrier_id
          //   ? Yup.string().required('A Empresa Obrigatória')
          //   : Yup.string(),
          password: Yup.string().min(
            6,
            'A senha deve ter no mínimo 6 caracteres',
          ),
          password_confirmation: Yup.string().oneOf(
            [Yup.ref('password'), undefined],
            'As Senhas não coincidem',
          ),
        });

        await schema.validate(data, { abortEarly: false });

        // eslint-disable-next-line no-param-reassign
        delete data.password_confirmation;

        if (data.branch_id) {
          await api.post(`/users`, data);
        }

        history.push('/users');

        addToast({
          title: 'Usuário adicionado com sucesso!',
          type: 'success',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro ao adicionar o usuário',
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Layout>
      <div className="container mx-auto">
        <h2 className="text-2xl text-opaque mt-2 mb-5">
          Cadastrar um novo usuário
        </h2>

        <div className="rounded-md shadow-md px-5 py-5 bg-white mt-5">
          <div className="my-2 ml-3">
            <span className="text-lg font-bold text-opaque">
              Dados do Usuário
            </span>
          </div>
          <hr className="mx-3" />
          <Form ref={formRef} onSubmit={handleSubmit}>
            <div className="flex flex-wrap w-full flex-row">
              <div className="p-2 w-full md:w-4/12">
                <label className="font-bold ml-1">Nome</label>
                <Input type="text" name="name" placeholder="Nome Completo" />
              </div>

              <div className="p-2 w-full md:w-4/12">
                <label className="font-bold ml-1">E-mail</label>
                <Input type="text" name="email" placeholder="E-mail" />
              </div>

              <div className="p-2 w-full md:w-4/12">
                <label className="font-bold ml-1">Senha</label>
                <Input icon={FiLock} type="password" name="password" />
              </div>
              <div className="p-2 w-full md:w-4/12">
                <label className="font-bold ml-1">Confirme a senha</label>
                <Input
                  icon={FiLock}
                  type="password"
                  name="password_confirmation"
                />
              </div>

              <div className="p-2 w-full md:w-4/12">
                <label className="font-bold ml-1">Tipo</label>
                <Select name="role_id" options={roles} />
              </div>

              <div className="p-2 w-full md:w-4/12">
                <label className="font-bold ml-1">Filial</label>
                <Select name="branch_id" options={branches} />
              </div>
            </div>
            <div className="grid grid-cols-1 mt-5 px-2">
              <div className="flex justify-end">
                <div className="w-32 mr-2">
                  <Button
                    type="submit"
                    theme="dark"
                    size="medium"
                    onClick={() => history.push('/users')}
                  >
                    Voltar
                  </Button>
                </div>

                <div className="w-32">
                  <Button type="submit" theme="primary" size="medium">
                    Salvar
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Layout>
  );
};

export default NewUser;
