import React, { createContext, useContext, useCallback, useRef } from 'react';
import LoadingBar from 'react-top-loading-bar';

interface LoadingbarContextData {
  staticStart: () => void;
  complete: () => void;
}

const LoadingToBarContext = createContext<LoadingbarContextData>(
  {} as LoadingbarContextData,
);

export const LoadingTopBarProvider: React.FC = ({ children }) => {
  const ref = useRef<LoadingbarContextData>(null);

  const staticStart = useCallback(() => {
    ref.current?.staticStart();
  }, []);

  const complete = useCallback(() => {
    ref.current?.complete();
  }, []);

  return (
    <LoadingToBarContext.Provider value={{ staticStart, complete }}>
      <LoadingBar color="#33cabb" height={3} ref={ref} shadow />
      {children}
    </LoadingToBarContext.Provider>
  );
};

export function useLoadingTopBar(): LoadingbarContextData {
  const context = useContext(LoadingToBarContext);

  if (!context) {
    throw new Error('useToast must be used within a Loading Top Bar');
  }

  return context;
}
