import React from 'react';

import { AuthProvider } from './Authentication';
import { LoadingProvider } from './Loading';
import { ToastProvider } from './Toast';
import { LoadingTopBarProvider } from './TopLoadingBar';

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <LoadingProvider>
      <ToastProvider>
        <LoadingTopBarProvider>{children}</LoadingTopBarProvider>
      </ToastProvider>
    </LoadingProvider>
  </AuthProvider>
);

export default AppProvider;
