/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
import React, { useCallback } from 'react';
import { IconBaseProps } from 'react-icons';
import { MdKeyboardArrowRight, MdKeyboardArrowDown } from 'react-icons/md';
import { useLocation } from 'react-router-dom';

import Logo from '../../../assets/logo-white.png';
import { SidebarData } from '../../../config/Sidebar';
import { useAuth } from '../../../hooks/Authentication';
import {
  Container,
  LogoContent,
  Menu,
  ContainerMenuItem,
  ItemLink,
  DropdownLink,
  Image,
  SidebarLabel,
} from './styles';

interface SidebarItemProps {
  item: ItemProps;
}

interface ItemProps {
  title: string;
  path: string;
  icon?: React.ComponentType<IconBaseProps>;
  role: string[];
  subNav: SubNavProps[];
}

interface SubNavProps {
  title: string;
  path: string;
  role: string[];
  icon?: React.ComponentType<IconBaseProps>;
}

type SidebarProps = {
  active: boolean;
};

const Sidebar: React.FC<SidebarProps> = ({ active }) => {
  const { user } = useAuth();
  return (
    <Container active={active}>
      <LogoContent>
        <Image src={Logo} alt="Logo" />
      </LogoContent>
      <Menu>
        {SidebarData.map((item, index) =>
          item.role.map(
            role =>
              role === user.role && <SidebarItem item={item} key={index} />,
          ),
        )}
      </Menu>
    </Container>
  );
};

const SidebarItem: React.FC<SidebarItemProps> = ({ item }) => {
  const { user } = useAuth();
  const [subnav, setSubnav] = React.useState(false);
  const location = useLocation();

  const showSubnav = () => setSubnav(!subnav);

  const handleTabActive = useCallback(
    (name: string) => {
      if (location.pathname.includes(name)) {
        return true;
      }
      return false;
    },
    [location.pathname],
  );

  const { icon: Icon } = item;

  return (
    <ContainerMenuItem>
      <ItemLink
        href={item.subNav.length === 0 ? item.path : '#'}
        active={handleTabActive(item.path)}
        onClick={item.subNav && showSubnav}
      >
        {Icon && <Icon size={20} />}
        {item.title}
        <span>
          {subnav && <MdKeyboardArrowDown size={15} />}
          {!subnav && <MdKeyboardArrowRight size={15} />}
        </span>
      </ItemLink>
      {subnav &&
        item.subNav.map((item, index) => {
          return item.role.map(role => {
            if (role === user.role) {
              const { icon: Icon } = item;
              return (
                <DropdownLink to={item.path} key={index}>
                  {Icon && <Icon size={12} />}
                  <SidebarLabel>{item.title}</SidebarLabel>
                </DropdownLink>
              );
            }
            return null;
          });
        })}
    </ContainerMenuItem>
  );
};

export default Sidebar;
