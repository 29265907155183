/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { FiX, FiEye } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import * as Yup from 'yup';

import Layout from '../../../../components/Layout';
import {
  Button,
  Input,
  Select,
  SelectMulti,
  Dropzone,
  InputMask,
} from '../../../../components/Ui';
import { useLoading } from '../../../../hooks/Loading';
import { useToast } from '../../../../hooks/Toast';
import api from '../../../../services/api';
import getValidationErrors from '../../../../utils/getValidationErrors';

interface IFileProps extends File {
  preview: string;
  path: string;
  success: boolean;
}

type FormData = {
  contract_reference_id?: string;
  order_reference_id?: string;

  delivery_customer_id: string;
  delivery_customer_address_id: string;

  billing_branch_id: string;
  shipping_branch_id: string;

  product_id: string;
  amount_freight: string;
  weight: string;
  kind: string;

  guide?: string;
  guide_photo?: string;
  carriers: ICarriers;
};

type ICarrierProps = {
  id: string;
  name: string;
};

type IAddressesProps = {
  value: string;
  label: string;
};

type ICarriers = {
  carrier_id: string;
};

type ICustomerSelect = {
  value: string;
  label: string;
  quantity: number;
  addresses: IAddressesProps[];
};

type IBoardingPlace = {
  id: string;
  name: string;
};

type SelectProps = {
  value: string;
  label: string;
};

const NewBatch: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [boardingBranches, setBoardingBranches] = useState<SelectProps[]>([]);
  const [carriers, setCarriers] = useState<SelectProps[]>([]);
  const [customers, setCustomers] = useState<ICustomerSelect[]>([]);
  const [products, setProducts] = useState<SelectProps[]>([]);
  const [addresses, setAddresses] = useState<IAddressesProps[]>([]);
  const [guide, setGuide] = useState<any>({});
  const history = useHistory();
  const { setLoading } = useLoading();
  const { addToast } = useToast();

  const getBoardingBranches = useCallback(async () => {
    setLoading(true);
    const response = await api.get('/branches');
    setBoardingBranches(
      response.data.data.map((place: IBoardingPlace) => {
        return {
          value: place.id,
          label: place.name,
        };
      }),
    );
    setLoading(false);
  }, [setLoading]);

  const getCarriers = useCallback(async () => {
    setLoading(true);
    const response = await api.get('/carriers?limit=10000');
    setCarriers(
      response.data.data.map((carrier: ICarrierProps) => {
        return {
          value: carrier.id,
          label: carrier.name,
        };
      }),
    );
    setLoading(false);
  }, [setLoading]);

  const getCustomers = useCallback(async () => {
    setLoading(true);
    const response = await api.get('/customers?limit=10000');

    setCustomers(
      response.data.data.map((carrier: any) => {
        return {
          value: carrier.id,
          label: carrier.name,
        };
      }),
    );
    setLoading(false);
  }, [setLoading]);

  const getProducts = useCallback(async () => {
    setLoading(true);
    const response = await api.get('/products?limit=10000');
    setProducts(
      response.data.data.map((item: any) => {
        return {
          value: item.id,
          label: item.name,
        };
      }),
    );
    setLoading(false);
  }, [setLoading]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getBoardingBranches();
      getCarriers();
      getCustomers();
      getProducts();
    }, 200);
    return () => clearTimeout(timeoutId);
  }, [getBoardingBranches, getCarriers, getCustomers, getProducts]);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        formRef.current?.setErrors({});

        delete data.guide_photo;

        const schema = Yup.object().shape({
          contract_reference_id: Yup.string().notRequired().nullable(),
          order_reference_id: Yup.string().notRequired().nullable(),

          shipping_branch_id: Yup.string().required(
            'Filial de embarque é Obrigatório',
          ),
          billing_branch_id: Yup.string().required(
            'Filial de faturamento é Obrigatório',
          ),

          customer_id: Yup.string().required('Cliente  é Obrigatório'),
          delivery_customer_id: Yup.string().required(
            'Cliente para Entrega é Obrigatório',
          ),
          delivery_customer_address_id: Yup.string().required(
            'O endereco do cliente é Obrigatório',
          ),

          product_id: Yup.string().required('Produto é Obrigatório'),
          weight: Yup.string().required('A Quantidade é Obrigatória'),
          amount_freight: Yup.string().required('Valor do Frete é Obrigatório'),
          kind: Yup.string().required('A Espécie é Obrigatória'),

          carriers: Yup.array().of(
            Yup.string().required('Transportadora é Obrigatório'),
          ),
        });

        await schema.validate(data, { abortEarly: false });

        const amount_freight = String(data.amount_freight).slice(3, 10);
        data.amount_freight = amount_freight
          .replace('.', '')
          .replace(/,/g, '.');

        data.weight = data.weight.replace(/\D/g, '');

        // eslint-disable-next-line prefer-const
        let formData = new FormData();

        Object.entries(data).forEach(([key, value]) => {
          formData.append(key, value as any);
        });

        if (guide?.file) {
          formData.append('guide_photo', guide.file.get('guide_photo'));
        }

        await api.post(`/batches`, formData);

        history.push('/batches');

        addToast({
          title: 'Lote Cadastrado com com sucesso!',
          type: 'success',
        });
      } catch (err: any) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        if (err?.response?.data) {
          addToast({
            type: 'error',
            title: 'Ocorreu um erro ao cadastrar o lote.',
            description: err.response.data.message,
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [guide],
  );

  const handleDropZoneGuide = useCallback(async acceptedFiles => {
    const filteredFile = acceptedFiles[0];

    const formData = new FormData();

    formData.append('guide_photo', filteredFile);

    setGuide({
      file: formData,
      name: filteredFile.name,
      uri: URL.createObjectURL(filteredFile),
    });
  }, []);

  const handleSetProductQuantity = useCallback(
    async (value: any) => {
      const selectedCustomer = customers.find(
        customer => customer.value === value,
      );

      const response = await api.get(
        `/address/customers/${selectedCustomer?.value}`,
      );

      setAddresses(
        response.data.map((data: any) => {
          return {
            value: data.id,
            label: `${data.state_registration} - ${data.city.name} - ${data.street}`,
          };
        }),
      );
    },
    [customers],
  );

  return (
    <Layout>
      <div className="container mx-auto">
        <h2 className="text-2xl text-opaque mt-2 mb-5">
          Novo Lote de Transporte
        </h2>

        <div className="rounded-md shadow-md px-5 py-5 bg-white mt-5">
          <div className="my-2 ml-3">
            <span className="text-lg font-bold text-opaque">Dados do lote</span>
          </div>
          <hr className="mx-3" />
          <Form ref={formRef} onSubmit={handleSubmit}>
            <input type="text" name="guide_url" hidden />
            <div className="flex flex-wrap w-full flex-row">
              <div className="p-2 w-full md:w-2/12">
                <label className="font-bold ml-1">Contrato</label>
                <Input type="text" name="contract_reference_id" />
              </div>

              <div className="p-2 w-full md:w-2/12">
                <label className="font-bold ml-1">Pedido</label>
                <Input type="text" name="order_reference_id" />
              </div>

              <div className="p-2 w-full md:w-4/12">
                <label className="font-bold ml-1">Filial de Embarque</label>
                <Select name="shipping_branch_id" options={boardingBranches} />
              </div>

              <div className="p-2 w-full md:w-4/12">
                <label className="font-bold ml-1">Filial de Faturamento</label>
                <Select name="billing_branch_id" options={boardingBranches} />
              </div>
            </div>

            <div className="flex flex-wrap w-full flex-row">
              <div className="p-2 w-full sm:w-full md:w-4/12">
                <label className="font-bold ml-1">Cliente</label>
                <Select name="customer_id" options={customers} />
              </div>
              <div className="p-2 w-full md:w-4/12">
                <label className="font-bold ml-1">Cliente para entrega</label>
                <Select
                  name="delivery_customer_id"
                  options={customers}
                  selectedValue={value => handleSetProductQuantity(value)}
                />
              </div>

              <div className="p-2 w-full md:w-4/12">
                <label className="font-bold ml-1">Endereço</label>
                <Select
                  name="delivery_customer_address_id"
                  options={addresses}
                />
              </div>
            </div>

            <div className="flex flex-wrap w-full flex-row">
              <div className="p-2 w-full md:w-3/12">
                <label className="font-bold ml-1">Espécie</label>
                <Select
                  name="kind"
                  options={[
                    { value: 'BIG BAG', label: 'BIG BAG' },
                    { value: 'GRANEL', label: 'GRANEL' },
                  ]}
                />
              </div>
              <div className="p-2 w-full md:w-3/12">
                <label className="font-bold ml-1">Valor do Frete R$</label>
                <InputMask
                  type="text"
                  name="amount_freight"
                  mask={createNumberMask({
                    prefix: 'R$ ',
                    thousandsSeparatorSymbol: '.',
                    allowDecimal: true,
                    decimalSymbol: ',',
                    requireDecimal: false,
                    integerLimit: 3,
                  })}
                />
              </div>
            </div>

            <div className="flex flex-wrap w-full flex-row">
              <div className="p-2 w-full md:w-5/12">
                <label className="font-bold ml-1">Transportadoras</label>
                <SelectMulti name="carriers" options={carriers} />
              </div>
            </div>

            <div className="mt-5">
              <hr className="mx-3" />

              <div className="flex flex-wrap w-full flex-row">
                <div className="p-2 w-full sm:w-6/12 md:w-12/12">
                  <label className="font-bold ml-1">Produto</label>
                  <Select name="product_id" options={products} />
                </div>
                <div className="p-2 w-full md:w-4/12">
                  <label className="font-bold ml-1">Quantidade</label>
                  <InputMask
                    type="text"
                    name="weight"
                    mask={createNumberMask({
                      prefix: '',
                      thousandsSeparatorSymbol: '.',
                      allowDecimal: true,
                      decimalSymbol: ',',
                      requireDecimal: false,
                      integerLimit: 8,
                    })}
                  />
                </div>
              </div>
            </div>

            <div className="mt-5">
              <hr className="mx-3" />

              <div className="p-2 w-full sm:w-12/12 md:w-12/12">
                <label className="font-bold ml-1">Observação</label>
                <Input type="text" name="guide" />
              </div>

              {!guide.uri && (
                <div className="mx-auto px-2 mt-2">
                  <Dropzone
                    accept="image/*, application/pdf"
                    onDrop={handleDropZoneGuide}
                    maxSize={2000000}
                    multiple={false}
                    isAccept="Solte aqui seu arquivo"
                    isReject="Esse formato de aquivo não é aceito"
                    isActive="Arraste e solte ou clique para adicionar"
                  />
                </div>
              )}
              {guide.uri && (
                <div className="flex justify-between mx-2 px-4 py-2 mt-2 border border-dashed rounded-md">
                  <div className="">{guide.name}</div>
                  <div className="flex flex-row">
                    <FiEye className="cursor-pointer mr-2" size={20} />
                    <FiX
                      className="cursor-pointer"
                      size={20}
                      onClick={() => setGuide({} as IFileProps)}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="grid grid-cols-1 mt-5">
              <div className="flex justify-end">
                <div className="w-32">
                  <Button
                    onClick={() => history.push('/batches')}
                    color="white"
                    theme="dark"
                    size="medium"
                  >
                    Voltar
                  </Button>
                </div>
                <div className="w-32 ml-4">
                  <Button
                    type="submit"
                    color="white"
                    theme="primary"
                    size="medium"
                  >
                    Salvar
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Layout>
  );
};

export default NewBatch;
