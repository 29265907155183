/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Layout from '../../../../components/Layout';
import { Button } from '../../../../components/Ui';
import { useLoading } from '../../../../hooks/Loading';
import api from '../../../../services/api';

interface Params {
  id: string;
}

const ShowCarrier: React.FC = () => {
  const history = useHistory();
  const [data, setData] = useState<any>();
  const { id } = useParams<Params>();
  const { setLoading } = useLoading();

  const getDataToApi = useCallback(async () => {
    setLoading(true);
    const response = await api.get(`/batches/${id}`);

    setData(response.data);

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getDataToApi();
    }, 200);
    return () => clearTimeout(timeoutId);
  }, [getDataToApi]);

  return (
    <Layout>
      <div className="container mx-auto">
        <h2 className="text-2xl text-opaque mt-2 mb-5">Informações do lote</h2>

        <div className="rounded-md shadow-md px-5 py-5 bg-white mt-5">
          <div className="my-2 ml-3">
            <span className="text-lg font-bold text-opaque">Dados do lote</span>
          </div>
          <hr className="mx-3" />
          <div className="flex flex-wrap w-full flex-row px-1">
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-3/12 p-2">
              <span className="text-sm font-bold">Contrato: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {data?.contract_reference_id}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-3/12 p-2">
              <span className="text-sm font-bold">Pedido: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {data?.order_reference_id}
              </span>
            </div>
          </div>
          <div className="flex flex-wrap w-full flex-row px-1 mt-2">
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
              <span className="text-sm font-bold">Carregado em: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {data?.shipping_branch?.name}
              </span>
            </div>
          </div>
          <div className="flex flex-wrap w-full flex-row px-1">
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
              <span className="text-sm font-bold">Faturado em: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {data?.billing_branch?.name}
              </span>
            </div>
          </div>
          <div className="my-2 ml-3 mt-6">
            <span className="text-lg font-bold text-opaque">Produto</span>
          </div>
          <hr className="mx-3" />
          <div className="flex flex-wrap w-full flex-row px-1">
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-3/12 p-2">
              <span className="text-sm font-bold">Produto: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {data?.product?.name}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-3/12 p-2">
              <span className="text-sm font-bold">Espécie: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {data?.kind}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-3/12 p-2">
              <span className="text-sm font-bold">Volume: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {new Intl.NumberFormat('pt-BR').format(
                  data?.weight ? data?.weight : 0,
                )}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-3/12 p-2">
              <span className="text-sm font-bold">Saldo: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {new Intl.NumberFormat('pt-BR').format(
                  data?.balance ? data?.balance : 0,
                )}
              </span>
            </div>
          </div>
          <div className="my-2 ml-3 mt-8">
            <span className="text-lg font-bold text-opaque">Cliente</span>
          </div>
          <hr className="mx-3" />
          <div className="flex flex-wrap w-full flex-row px-1">
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-3/12 p-2">
              <span className="text-sm font-bold">Cliente: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {data?.customer?.name}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-3/12 p-2">
              <span className="text-sm font-bold">Cliente Entrega: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {data?.delivery_customer?.name}
              </span>
            </div>
          </div>
          <div className="flex flex-wrap w-full flex-row px-1">
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-3/12 p-2">
              <span className="text-sm font-bold">Logradouro: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {data?.address?.public_place}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-3/12 p-2">
              <span className="text-sm font-bold">Endereço: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {data?.address?.street}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-3/12 p-2">
              <span className="text-sm font-bold">Numero: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {data?.address?.number}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-3/12 p-2">
              <span className="text-sm font-bold">Bairro: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {data?.address?.neighborhood}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-3/12 p-2">
              <span className="text-sm font-bold">Cidade: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {data?.address?.city?.name}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-3/12 p-2">
              <span className="text-sm font-bold">Estado: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {data?.address?.state?.name}
              </span>
            </div>
          </div>
          <div className="my-2 ml-3 mt-8">
            <span className="text-lg font-bold text-opaque">
              Transportadoras notificadas
            </span>
          </div>
          <hr className="mx-3" />
          <div className="flex flex-wrap w-full flex-row px-1">
            <div className="block px-2 w-full">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="w-full">
                  <tr>
                    <th className="px-6 py-3 text-left text-sm font-medium text-gray-500 tracking-wider">
                      Transportadora
                    </th>
                    <th className="px-6 py-3 text-left text-sm font-medium text-gray-500 tracking-wider">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody className="w-full bg-white divide-y divide-gray-200">
                  {data?.notifications.map((item: any) => (
                    <tr>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className="text-sm text-gray-500">
                          {item?.carrier?.name}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {item.carrier_answered_at === null
                          ? 'Pendente'
                          : item.approved === null
                          ? 'Aprovação da Empresa'
                          : item.approved === true
                          ? 'Aprovado'
                          : 'Recusado'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="grid grid-cols-1 mt-5 px-2">
            <div className="flex justify-end">
              <div className="w-32">
                <Button
                  onClick={() => history.push('/batches')}
                  color="opaque"
                  theme="dark"
                  size="medium"
                >
                  Voltar
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ShowCarrier;
