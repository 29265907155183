/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import React, { useState, useCallback, useEffect } from 'react';
import { FiSearch, FiInfo, FiX } from 'react-icons/fi';

// import { useHistory } from 'react-router-dom';
import DraggableDialog from '../../../components/DraggableDialog';
import Layout from '../../../components/Layout';
import { Table } from '../../../components/Ui';
import { useLoading } from '../../../hooks/Loading';
import { useToast } from '../../../hooks/Toast';
import api from '../../../services/api';
import { Search } from './styles';

const TransportOrders: React.FC = () => {
  const [data, setData] = useState<[]>([]);
  const { setLoading } = useLoading();
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedId, setSelectedId] = useState<string | number>();
  const [openDialogCancelOrder, setOpenDialogCancelOrder] = useState(false);
  const [total, setTotal] = useState(0);
  const [input, setInput] = useState('');
  const { addToast } = useToast();

  /**
   * Get data to api
   */
  const getDataToApi = useCallback(async () => {
    setLoading(true);
    const response = await api.get(
      `/transport-orders?page=${page}&limit=${rowsPerPage}${
        input ? `&search=${input}` : ''
      }`,
    );

    setTotal(response.data.total);
    setRowsPerPage(response.data.per_page);
    setPage(response.data.current_page);
    setData(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      response.data.data.map((order: any) => {
        return {
          id: order.id,
          code: order.reference_id,
          customer: order.batch.delivery_customer.name,
          driver: order.driver.name,
          license_plate: order.truck.license_plate,
          status: order.type.description,
          created_at: new Date(order.created_at).toLocaleDateString(),
        };
      }),
    );
    setLoading(false);
  }, [input, rowsPerPage, page]);
  /**
   * Reload data pages and rows per page
   */
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getDataToApi();
    }, 200);
    return () => clearTimeout(timeoutId);
  }, [rowsPerPage, page]);

  /**
   * Reload data with input
   */
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (data.length > 0) {
      const timeoutId = setTimeout(() => getDataToApi(), 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [input]);

  /**
   * Set Input Change
   */
  const handleChangeSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setInput(value);

      if (value === '') {
        setTimeout(() => getDataToApi(), 1000);
      }
    },
    [],
  );

  /**
   * Set rows per page
   */
  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const number = event.target.value as unknown as number;
      setRowsPerPage(number);
    },
    [],
  );

  /**
   * Set page change
   */
  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage + 1);
  }, []);

  const handleCancelOrder = useCallback(async () => {
    try {
      await api.post(`/transport-orders/cancel/${selectedId}`);
      await getDataToApi();
    } catch (err: any) {
      addToast({
        type: 'error',
        title: 'Ocorreu um erro ao cadastrar o lote.',
        description: err.response.data.message,
      });
    }

    setSelectedId('');
    setOpenDialogCancelOrder(false);
  }, [selectedId]);

  return (
    <Layout>
      <div className="container mx-auto">
        <h2 className="text-2xl text-opaque mt-2 mb-5">Ordens de Transporte</h2>

        <div className="rounded-sm shadow-md px-5 py-5 bg-white mt-5">
          <div className="flex px-2 w-full flex-row">
            <Search>
              <FiSearch size={20} />
              <input
                type="text"
                name="search"
                placeholder="Filtre por código, nome, motorista ou placa"
                value={input}
                onChange={handleChangeSearch}
              />
            </Search>
          </div>
        </div>

        <div className="rounded-sm shadow-md px-5 py-5 bg-white mt-5">
          <div className="flex w-full px-2 mb-2">
            <span className="text-lg font-normal">
              Listagem de Orders de Transporte
            </span>
          </div>
          <Table
            title="Ordens em Transito"
            columns={[
              'Código',
              'Cliente',
              'Motorista',
              'Placa',
              'Status',
              'Data',
            ]}
            page={page}
            rowsPerPage={rowsPerPage}
            total={total}
            setPage={handleChangePage}
            setRowsPerPage={handleChangeRowsPerPage}
            data={data}
            editable={({ id, status }) => {
              let showStatus = true;
              if (status === 'Finalizada' || status === 'Cancelada') {
                showStatus = false;
              }
              return (
                <>
                  {showStatus && (
                    <Tooltip title="Ordem de Carregamento" placement="top">
                      <a
                        href={`${process.env.REACT_APP_API_URL}/transport-orders/reports/order/${id}`}
                      >
                        <IconButton>
                          <FiInfo />
                        </IconButton>
                      </a>
                    </Tooltip>
                  )}

                  {showStatus && (
                    <Tooltip
                      title="Cancelar Order de Carregamento"
                      placement="top"
                    >
                      <IconButton
                        onClick={() => {
                          setSelectedId(id);
                          setOpenDialogCancelOrder(true);
                        }}
                      >
                        <FiX />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              );
            }}
          />
        </div>
        {/* Modal de alterar senha */}
        <DraggableDialog
          title="Atenção"
          description="Deseja realmente cancelar essa ordem de carregamento?"
          titleAcceptButton="Confirmar"
          open={openDialogCancelOrder}
          accept={() => handleCancelOrder()}
          cancel={() => setOpenDialogCancelOrder(false)}
        />
      </div>
    </Layout>
  );
};

export default TransportOrders;
