/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import * as Yup from 'yup';

import Layout from '../../../../components/Layout';
import { Button, InputMask, Select } from '../../../../components/Ui';
import { useLoading } from '../../../../hooks/Loading';
import { useToast } from '../../../../hooks/Toast';
import api from '../../../../services/api';
import { FormaterNumberToCPFCNPJ } from '../../../../utils/ConvertDataTypes';
import getValidationErrors from '../../../../utils/getValidationErrors';

type ICarrier = {
  id: string;
  name: string;
};

type IUser = {
  id: string;
  name: string;
};

type ICustomer = {
  id: string;
  name: string;
  cnpj: string;
  address: IAddress;
};

type IProduct = {
  id: string;
  name: string;
};

type ITrucker = {
  id: string;
  name: string;
  user: IUser;
};

type ITruck = {
  id: string;
  brand: string;
  model: string;
  color: string;
  year_manufacture?: string;
  license_plate: string;
};

type ICity = {
  name: string;
};

type IState = {
  name: string;
};

type IAddress = {
  neighborhood: string;
  public_place: string;
  street: string;
  complement: string;
  number: string;
  zip_code: string;
  city: ICity;
  state: IState;
};

type IInitialData = {
  id: string;
  truck: ITruck;
  carrier: ICarrier;
  trucker: ITrucker;
  customer: ICustomer;
  address: IAddress;
  product: IProduct;
  customer_delivery: ICustomer;
  kind: string;
  guide: string;
  guide_url: string;
  balance: number;
};

type FormData = {
  id: string;
  batch_id: string;
  carrier_id: string;
  driver_id: string;
  truck_id: string;
  weight: string;
};

interface Params {
  batch_id: string;
}

const NewTransportOrder: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [initialData, setInitialData] = useState<IInitialData>(
    {} as IInitialData,
  );
  const [trucks, setTrucks] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const history = useHistory();
  const { setLoading } = useLoading();
  const { addToast } = useToast();
  const { batch_id } = useParams<Params>();

  const getTrucksData = useCallback(async () => {
    setLoading(true);
    const response = await api.get('/trucks?limit=10000');
    setTrucks(
      response.data.data.map((truck: ITruck) => {
        return {
          value: truck.id,
          label: `${truck.license_plate} - ${truck.brand} - ${truck.model} - ${truck.color}`,
        };
      }),
    );
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDriverData = useCallback(async () => {
    setLoading(true);
    const response = await api.get('/drivers?limit=10000');

    setDrivers(
      response.data.data.map((driver: any) => {
        return {
          value: driver.id,
          label: `${FormaterNumberToCPFCNPJ(driver.cpf)} - ${driver.name}`,
        };
      }),
    );
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDataToApi = useCallback(async () => {
    setLoading(true);
    const response = await api.get(`/batches/carriers/${batch_id}`);

    const { weight } = response.data;

    delete response.data.weight;

    setInitialData({
      ...response.data,
      amount: new Intl.NumberFormat().format(response.data.amount),
      programmed_quantity: weight,
    });

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batch_id]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getDataToApi();
      getTrucksData();
      getDriverData();
    }, 200);
    return () => clearTimeout(timeoutId);
  }, [getDataToApi, getTrucksData, getDriverData]);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        formRef.current?.setErrors({});

        data.batch_id = initialData.id;

        const schema = Yup.object().shape({
          weight: Yup.string().required('O Peso é Obrigatório'),
          truck_id: Yup.string().required('O Caminhão é Obrigatório'),
          driver_id: Yup.string().required('O Motorista é Obrigatório'),
        });

        await schema.validate(data, { abortEarly: false });

        data.weight = data.weight.replace(/\D/g, '');

        await api.post(`/transport-orders/`, data);

        history.push('/transport-orders');

        addToast({
          title: 'Ordem de Transporte Criada com sucesso!',
          type: 'success',
        });
      } catch (err: any) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        if (err.response) {
          if (
            err.response.data.message ===
            'The transport order has a greater weight than programmed'
          ) {
            addToast({
              type: 'error',
              title:
                'O peso disponível é menor que o peso informado, consulte a empresa para mais informações',
            });

            return;
          }
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro ao criar a Order de Transporte',
          // description: err.response.data.message,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialData],
  );

  return (
    <Layout>
      <div className="container mx-auto">
        <h2 className="text-2xl text-opaque mt-2 mb-5">
          Nova Ordem de Transporte
        </h2>

        <div className="rounded-md shadow-md px-5 py-5 bg-white mt-5">
          <div className="my-2 ml-3">
            <span className="text-lg font-bold text-opaque">
              Dados do Cliente
            </span>
          </div>
          <hr className="mx-3" />

          <div className="flex flex-wrap w-full flex-row px-1">
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
              <span className="text-sm font-bold">Nome: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {initialData?.customer?.name}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
              <span className="text-sm font-bold">CNPJ: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {initialData?.customer?.cnpj}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
              <span className="text-sm font-bold">Estado: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {/* {initialData?.customer.address.state.name} */}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
              <span className="text-sm font-bold">Cidade: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {initialData?.address?.city?.name}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
              <span className="text-sm font-bold">Endereço: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {`${initialData?.address?.public_place} ${initialData?.address?.street} ${initialData?.address?.number}`}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
              <span className="text-sm font-bold">Bairro: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {initialData?.address?.neighborhood}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
              <span className="text-sm font-bold">Complemento: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {initialData?.address?.complement}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
              <span className="text-sm font-bold">CEP: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {initialData?.address?.zip_code}
              </span>
            </div>
          </div>

          <div className="my-2 ml-3">
            <span className="text-lg font-bold text-opaque">
              Cliente para Entrega
            </span>
          </div>
          <hr className="mx-3" />
          <div className="flex flex-wrap w-full flex-row px-1">
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
              <span className="text-sm font-bold">Nome: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {initialData?.customer_delivery?.name}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
              <span className="text-sm font-bold">CNPJ: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {initialData?.customer_delivery?.cnpj}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
              <span className="text-sm font-bold">Estado: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {initialData?.address?.state?.name}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
              <span className="text-sm font-bold">Cidade: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {initialData?.address?.city?.name}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
              <span className="text-sm font-bold">Endereço: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {`${initialData?.address?.public_place} ${initialData?.address?.street} ${initialData?.address?.number}`}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
              <span className="text-sm font-bold">Bairro: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {initialData?.address?.neighborhood}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
              <span className="text-sm font-bold">Complemento: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {initialData?.address?.complement}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
              <span className="text-sm font-bold">CEP: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {initialData?.address?.zip_code}
              </span>
            </div>
          </div>
          <div className="my-2 ml-3">
            <span className="text-lg font-bold text-opaque">Produto</span>
          </div>
          <hr className="mx-3" />
          <div className="flex flex-wrap w-full flex-row px-1">
            <div className="flex flex-col md:flex-row w-full sm:w-full md:w-8/12 p-2">
              <span className="text-sm font-bold">Produto: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {initialData?.product?.name}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-full md:w-4/12 p-2">
              <span className="text-sm font-bold">Espécie: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {initialData?.kind}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
              <span className="text-sm font-bold">Quantidade Disponível: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {Number(initialData?.balance).toLocaleString('pt-BR')}
              </span>
            </div>
          </div>

          <div className="my-2 ml-3">
            <span className="text-lg font-bold text-opaque">Obervação</span>
          </div>
          <hr className="mx-3" />

          <div className="flex flex-wrap w-full flex-row px-1">
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
              <span className="text-sm font-bold">Roteiro: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {initialData.guide}
              </span>
            </div>
            {initialData?.guide_url && (
              <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-6/12 p-2">
                <span className="text-sm font-bold">Arquivo de Roteiro: </span>
                <a
                  target="_blank"
                  href={`${initialData?.guide_url}`}
                  rel="noreferrer"
                >
                  <span className="ml-0 md:ml-1 text-sm font-light">
                    Clique aqui para baixar
                  </span>
                </a>
              </div>
            )}
          </div>

          <hr className="mx-3 mt-5" />

          <Form ref={formRef} onSubmit={handleSubmit} initialData={initialData}>
            <div className="flex flex-wrap w-full flex-row">
              <div className="p-2 w-full md:w-4/12">
                <label className="font-bold ml-1">Caminhão</label>
                <Select name="truck_id" options={trucks} />
              </div>

              <div className="p-2 w-full md:w-4/12">
                <label className="font-bold ml-1">Motorista</label>
                <Select name="driver_id" options={drivers} />
              </div>

              <div className="p-2 w-full md:w-3/12">
                <label className="font-bold ml-1">Peso Reservado</label>
                <InputMask
                  type="text"
                  name="weight"
                  mask={createNumberMask({
                    prefix: 'KG ',
                    thousandsSeparatorSymbol: '.',
                    allowDecimal: true,
                    decimalSymbol: ',',
                    requireDecimal: false,
                    integerLimit: 5,
                  })}
                />
              </div>
            </div>

            <div className="grid grid-cols-1 mt-5">
              <div className="flex justify-end">
                <div className="w-32">
                  <Button
                    onClick={() =>
                      history.push('/batches/solicitations/approved')
                    }
                    color="white"
                    theme="dark"
                    size="medium"
                  >
                    Voltar
                  </Button>
                </div>
                <div className="w-32 ml-4">
                  <Button
                    type="submit"
                    color="white"
                    theme="primary"
                    size="medium"
                  >
                    Salvar
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Layout>
  );
};

export default NewTransportOrder;
