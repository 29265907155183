/* eslint-disable no-multi-assign */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import { GoogleMap, LoadScript, Marker, InfoBox } from '@react-google-maps/api';
import React, { useState, useCallback, useEffect } from 'react';

import Layout from '../../../components/Layout';
import api from '../../../services/api';

const containerStyle = {
  width: '100%',
  height: '800px',
  display: 'flex',
};

const center = {
  lat: -12.5624216,
  lng: -55.7264434,
};

const options = { closeBoxURL: '', enableEventPropagation: true };

// window.google = window.google ? window.google : {};

const Geolocation: React.FC = () => {
  const [data, setData] = useState<any[]>([]);
  const [selectedMarker, setSelectedMarker] = useState<string>('');

  const handleGetData = useCallback(async () => {
    const response = await api.get('/transport-orders/locations');

    setData(
      response.data.map((order: any) => {
        return {
          id: order.id,
          truck: {
            license_plate: order.truck.license_plate,
          },
          driver: {
            name: order.driver.name,
          },
          location: {
            lat: order.locations.latitude,
            lng: order.locations.longitude,
          },
          product: {
            name: order.batch.product.name,
          },
          carrier: {
            name: order.carrier.name,
          },
          destination: {
            address: `${order.batch.address.street}, ${order.batch.address.city.name} - ${order.batch.address.state.name}`,
          },
        };
      }),
    );
  }, []);

  useEffect(() => {
    handleGetData();
  }, []);

  const handleShowInfoBox = useCallback(id => {
    setSelectedMarker(id);
  }, []);

  const [map, setMap] = React.useState(null);

  // eslint-disable-next-line no-shadow
  const onLoad = React.useCallback(
    // eslint-disable-next-line no-shadow
    function callback(map) {
      const bounds = new window.google.maps.LatLngBounds();

      data.forEach(order => {
        bounds.extend({
          lat: Number(order.location.lat),
          lng: Number(order.location.lng),
        });
      });

      map.fitBounds(bounds);
      setMap(map);
    },
    [data],
  );

  // eslint-disable-next-line no-shadow
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return (
    <Layout>
      <div className="container mx-auto">
        <div className="flex flex-row justify-between items-center px-2 mb-4 mt-2">
          <h2 className="text-2xl text-opaque">Localização da Frota</h2>
        </div>

        <div className="rounded-sm shadow-md px-5 py-5 bg-white mt-5">
          <LoadScript googleMapsApiKey="AIzaSyA3mu1l7E80aKkoxwfyoOfZvV0q7g3DN8c">
            {data.length > 0 && (
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={6}
                onLoad={onLoad}
                onUnmount={onUnmount}
              >
                {data.map(order => (
                  <Marker
                    key={order.id}
                    onClick={() => handleShowInfoBox(order.id)}
                    position={{
                      lat: Number(order.location.lat),
                      lng: Number(order.location.lng),
                    }}
                    icon={{
                      url: `${process.env.PUBLIC_URL}/assets/box-truck.png`,
                      size: new window.google.maps.Size(40, 40),
                      scaledSize: new window.google.maps.Size(40, 40),
                      anchor: new window.google.maps.Point(11, 11),
                    }}
                  >
                    {selectedMarker === order.id && (
                      <InfoBox options={options} position={order.location}>
                        <div className="bg-white w-full h-auto shadow-md mt-6 border border-opaque overflow-hidden px-4 py-2 rounded-md leading-5 text-lg">
                          <div className="flex flex-row w-full">
                            <span className="text-opaque font-bold">
                              Placa:
                            </span>
                            <span className="text-opaque font-light">
                              {order.truck.license_plate}
                            </span>
                          </div>
                          <div className="flex flex-row w-full">
                            <span className="text-opaque font-bold">
                              Motorista:
                            </span>
                            <span className="text-opaque font-light">
                              {order.driver.name}
                            </span>
                          </div>
                          <div className="flex flex-row w-full">
                            <span className="text-opaque font-bold">
                              Produto:
                            </span>
                            <span className="text-opaque font-light">
                              {order.product.name}
                            </span>
                          </div>
                          <div className="flex flex-row w-full">
                            <span className="text-opaque font-bold">
                              Transportadora:
                            </span>
                            <span className="text-opaque font-light">
                              {order.carrier.name}
                            </span>
                          </div>
                          <div className="flex flex-row w-full">
                            <span className="text-opaque font-bold">
                              Destino:
                            </span>
                            <span className="text-opaque font-light">
                              {order.destination.address}
                            </span>
                          </div>
                        </div>
                      </InfoBox>
                    )}
                  </Marker>
                ))}
              </GoogleMap>
            )}
          </LoadScript>
        </div>
      </div>
    </Layout>
  );
};

export default React.memo(Geolocation);
