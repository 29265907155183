/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import TablePagination from '@material-ui/core/TablePagination';
import React from 'react';
import { FiCheck, FiX } from 'react-icons/fi';

import { Container, Footer } from './styles';

interface EditableProps {
  id: number | string;
  [key: string]: string | number;
}

interface DataProps {
  [key: string]: string | number;
}

interface TableProps {
  title?: string;
  data: DataProps[];
  columns: Array<string>;
  setRowsPerPage: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setPage: (event: unknown, newPage: number) => void;
  total: number;
  rowsPerPage: number;
  page: number;
  editable?: React.ComponentType<EditableProps>;
}

const TableComponent: React.FC<TableProps> = ({
  data,
  columns,
  page,
  rowsPerPage,
  total,
  setPage,
  setRowsPerPage,
  editable: Editable,
}) => {
  return (
    <Container>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="">
          <tr>
            {columns.map((header, index) => (
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                key={index}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {data.length === 0 && (
            <tr>
              <td
                className="px-6 py-4 whitespace-nowrap"
                colSpan={columns.length + 1}
                style={{ textAlign: 'center' }}
              >
                Nenhum dado Encontrado
              </td>
            </tr>
          )}
          {Object.values(data).map((item, index) => (
            <tr key={index}>
              {Object.entries(item).map((key, index) => {
                return (
                  key[0] !== 'id' && (
                    <td className="px-6 py-4 whitespace-nowrap" key={index}>
                      <span className="text-sm text-gray-500">
                        {typeof key[1] === 'boolean' ? (
                          key[1] ? (
                            <FiCheck className="color-success" />
                          ) : (
                            <FiX className="color-danger" />
                          )
                        ) : (
                          key[1]
                        )}
                      </span>
                    </td>
                  )
                );
              })}
              {Editable && (
                <td className="table-actions">
                  <Editable {...item} id={item.id} />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>

      <Footer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          onChangePage={setPage}
          onChangeRowsPerPage={setRowsPerPage}
          labelRowsPerPage="Registros por pagina"
          nextIconButtonText="Proxima pagina"
          backIconButtonText="Pagina anterior"
        />
      </Footer>
    </Container>
  );
};

export default TableComponent;
