import styled, { css } from 'styled-components';

// import { defaultTheme } from '../../../styles/theme';

interface ContainerProps {
  size?: 'small' | 'medium' | 'large';
  buttonTheme?:
    | 'primary'
    | 'secondary'
    | 'dark'
    | 'light'
    | 'danger'
    | 'borded-primary'
    | 'borded-secondary'
    | 'borded-dark'
    | 'borded-light';
}

export const Container = styled.button<ContainerProps>`
  border: 0;
  /* padding: 5px 10px 5px 10px; */
  border-radius: 6px;
  color: #fff;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 2;

  font-weight: 600;
  font-size: 14px;

  cursor: pointer;
  transition: opacity 0.2s;

  svg {
    margin-right: 8px;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5 !important;
  }

  &:focus {
    outline: 0;
    box-shadow: none;
  }

  ${props =>
    props.size === 'small' &&
    css`
      padding: 0px 10px 0px 10px;
      height: 34px;
    `}

  ${props =>
    props.size === 'medium' &&
    css`
      /* padding: 5px 20px 5px 20px; */
      height: 40px;
    `}

    ${props =>
    props.size === 'large' &&
    css`
      padding: 5px 10px 5px 10px;
      height: 42px;
      width: 100%;
    `}

  ${props =>
    props.buttonTheme === 'primary' &&
    css`
      border: none;
      color: ${props.theme.colors.white};
      background-color: ${props.theme.colors.primary};

      &:hover {
        opacity: 0.8;
      }
    `}

  ${props =>
    props.buttonTheme === 'secondary' &&
    css`
      border: none;
      color: ${props.theme.colors.white};
      background-color: ${props.theme.colors.secondary};

      &:hover {
        opacity: 0.8;
      }
    `}

    ${props =>
    props.buttonTheme === 'dark' &&
    css`
      border: none;
      color: ${props.theme.colors.white};
      background-color: ${props.theme.colors.opaque};

      &:hover {
        opacity: 0.8;
      }
    `}

      ${props =>
    props.buttonTheme === 'danger' &&
    css`
      border: none;
      color: ${props.theme.colors.white};
      background-color: ${props.theme.colors.red};

      &:hover {
        opacity: 0.8;
      }
    `}

    ${props =>
    props.buttonTheme === 'light' &&
    css`
      border: none;
      color: ${props.theme.colors.opaque};
      background-color: ${props.theme.colors.light};

      &:hover {
        opacity: 0.8;
      }
    `}

    ${props =>
    props.buttonTheme === 'borded-primary' &&
    css`
      border: 1px solid ${props.theme.colors.primary};
      color: ${props.theme.colors.primary};
      background-color: ${props.theme.colors.transparent};

      &:hover {
        transition: all 200ms ease-in-out;
        border: 1px solid ${props.theme.colors.primary};
        color: ${props.theme.colors.white};
        background-color: ${props.theme.colors.primary};
      }
    `}

    ${props =>
    props.buttonTheme === 'borded-secondary' &&
    css`
      border: 1px solid ${props.theme.colors.secondary};
      color: ${props.theme.colors.secondary};
      background-color: ${props.theme.colors.transparent};

      &:hover {
        transition: all 200ms ease-in-out;
        border: 1px solid ${props.theme.colors.secondary};
        color: ${props.theme.colors.white};
        background-color: ${props.theme.colors.secondary};
      }
    `}

    ${props =>
    props.buttonTheme === 'borded-dark' &&
    css`
      border: 1px solid ${props.theme.colors.opaque};
      color: ${props.theme.colors.opaque};
      background-color: ${props.theme.colors.transparent};

      &:hover {
        transition: all 200ms ease-in-out;
        border: 1px solid ${props.theme.colors.opaque};
        color: ${props.theme.colors.white};
        background-color: ${props.theme.colors.opaque};
      }
    `}

    ${props =>
    props.buttonTheme === 'borded-light' &&
    css`
      border: 1px solid ${props.theme.colors.white};
      color: ${props.theme.colors.white};
      background-color: ${props.theme.colors.transparent};

      &:hover {
        transition: all 200ms ease-in-out;
        border: 1px solid ${props.theme.colors.white};
        color: ${props.theme.colors.opaque};
        background-color: ${props.theme.colors.white};
      }
    `}
`;
