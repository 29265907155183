import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  width: 100%;
  height: 100vh;
  position: relative;

  grid-template-rows: auto 1fr;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    'sidebar header'
    'sidebar content';

  overflow: hidden;
`;
