import React from 'react';

const Page: React.FC = () => {
  return (
    <div className="container mx-auto">
      <h2 className="text-2xl text-opaque mt-2 mb-5">
        Política de Privacidade
      </h2>
      <div className="">/</div>
    </div>
  );
};

export default Page;
