import { Form as Unform } from '@unform/web';
import styled from 'styled-components';

export const Container = styled.div``;

export const Form = styled(Unform)`
  width: 100%;
`;

export const Content = styled.div`
  z-index: 999;
  display: flex;
  width: min(350px, 100%);
  height: 400px;
  flex-direction: column;
  padding: 0 20px;

  justify-content: center;
  align-items: center;
`;

export const Title = styled.h2`
  color: #1cb76a;
  font-size: 30px;
  font-weight: 600 !important;
  margin-bottom: 20px;
`;

export const Image = styled.img``;
