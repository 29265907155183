/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from 'react';
import { FiSearch } from 'react-icons/fi';

import Layout from '../../../components/Layout';
import { Table } from '../../../components/Ui';
import { useLoading } from '../../../hooks/Loading';
import api from '../../../services/api';
import { Search } from './styles';

type IRequest = {
  id: string;
  name: string;
  trade_name: string;
  cnpj: string;
};

const Clients: React.FC = () => {
  const [data, setData] = useState<IRequest[]>([]);
  const { setLoading } = useLoading();
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [total, setTotal] = useState(0);
  const [input, setInput] = useState('');

  /**
   * Get data to api
   */
  const getDataToApi = useCallback(async () => {
    setLoading(true);
    const response = await api.get(
      `/customers?page=${page}&limit=${rowsPerPage}${
        input ? `&search=${input}` : ''
      }`,
    );

    setPage(response.data.current_page);
    setTotal(response.data.total);
    setRowsPerPage(response.data.per_page);

    setData(
      response.data.data.map((client: IRequest) => {
        return {
          id: client.id,
          name: client.name,
          trade_name: client.trade_name,
          cnpj: client.cnpj,
        };
      }),
    );
    setLoading(false);
  }, [input, rowsPerPage, page]);

  /**
   * Reload data pages and rows per page
   */
  useEffect(() => {
    const timeoutId = setTimeout(() => getDataToApi(), 200);
    return () => clearTimeout(timeoutId);
  }, [rowsPerPage, page]);

  /**
   * Reload data with input
   */
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (data.length > 0) {
      const timeoutId = setTimeout(() => {
        getDataToApi();
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [input]);

  /**
   * Set Input Change
   */
  const handleChangeSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setInput(value);

      if (value === '') {
        setTimeout(() => getDataToApi(), 1000);
      }
    },
    [],
  );

  /**
   * Set rows per page
   */
  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const number = event.target.value as unknown as number;
      setRowsPerPage(number);
    },
    [],
  );

  /**
   * Set page change
   */
  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage + 1);
  }, []);

  return (
    <Layout>
      <div className="container mx-auto">
        <div className="flex flex-row justify-between items-center px-2 mb-4 mt-2">
          <h2 className="text-2xl text-opaque">Clientes</h2>
        </div>

        <div className="rounded-sm shadow-md px-5 py-5 bg-white mt-5">
          <div className="flex px-2 w-full flex-row">
            <Search>
              <FiSearch size={20} />
              <input
                type="text"
                name="search"
                placeholder="Filtre por nome e cnpj"
                value={input}
                onChange={handleChangeSearch}
              />
            </Search>
          </div>
        </div>

        <div className="rounded-sm shadow-md px-5 py-5 bg-white mt-5">
          <div className="flex w-full px-2 mb-2">
            <span className="text-lg font-normal">Listagem de clientes</span>
          </div>
          <Table
            title="Listagem de Clientes"
            columns={['Nome', 'Nome Fantasia', 'CPF/CNPJ']}
            page={page}
            rowsPerPage={rowsPerPage}
            total={total}
            setPage={handleChangePage}
            setRowsPerPage={handleChangeRowsPerPage}
            data={data}
          />
        </div>
      </div>
    </Layout>
  );
};

export default Clients;
