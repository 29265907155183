import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import Drivers from '../pages/Carrier/Drivers';
import EditDriver from '../pages/Carrier/Drivers/Edit';
import NewDriver from '../pages/Carrier/Drivers/New';
import Solicitations from '../pages/Carrier/Solicitations';
import Demand from '../pages/Carrier/Solicitations/Demand';
import Pending from '../pages/Carrier/Solicitations/Pending';
import SolicitationPendingOffer from '../pages/Carrier/Solicitations/Pending/Offers';
import TransportOrders from '../pages/Carrier/TransportOrders';
import NewTransportOrders from '../pages/Carrier/TransportOrders/New';
import Trucks from '../pages/Carrier/Trucks';
import EditTrucks from '../pages/Carrier/Trucks/Edit';
import NewTrucks from '../pages/Carrier/Trucks/New';
import Route from './Route';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route
        path="/solicitations/pending"
        exact
        component={Pending}
        isPrivate
      />

      <Route path="/solicitations" exact component={Solicitations} isPrivate />
      <Route path="/solicitations/demand" exact component={Demand} isPrivate />

      <Route
        path="/solicitations/demand/offer/:id"
        exact
        component={SolicitationPendingOffer}
        isPrivate
      />
      <Route path="/drivers" exact component={Drivers} isPrivate />
      <Route path="/drivers/new" exact component={NewDriver} isPrivate />
      <Route path="/drivers/edit/:id" exact component={EditDriver} isPrivate />
      <Route path="/trucks" exact component={Trucks} isPrivate />
      <Route path="/trucks/new" exact component={NewTrucks} isPrivate />
      <Route path="/trucks/edit/:id" exact component={EditTrucks} isPrivate />

      <Route
        path="/transport-orders"
        exact
        component={TransportOrders}
        isPrivate
      />

      <Route
        path="/transport-orders/new/:batch_id"
        exact
        component={NewTransportOrders}
        isPrivate
      />
      <Redirect from="*" to="/" />
    </Switch>
  );
};

export default Routes;
