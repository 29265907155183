/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import Layout from '../../../../components/Layout';
import { Button, Input, Select } from '../../../../components/Ui';
import { useLoading } from '../../../../hooks/Loading';
import { useToast } from '../../../../hooks/Toast';
import api from '../../../../services/api';
import getValidationErrors from '../../../../utils/getValidationErrors';

type FormData = {
  id: string;
  name: string;
  email: string;
  username: string;
  role_id: string;
  password: string;
  password_confirmation?: string;
  branch_id?: string;
  carrier_id?: string;
};

type IResponse = {
  id: string;
  name: string;
  email: string;
  username: string;
  active?: string;
  created_at?: string;
  hasRole?: IHasRole;
  role_id?: string;
  branch_id?: string;
  carrier_id?: string;
};

type IRoles = {
  id: string;
  name: string;
  description: string;
};

type SelectProps = {
  value: string;
  label: string;
  name?: string;
};

type IHasRole = {
  role: IRoles;
};

type Companies = {
  id: string;
  name: string;
  cnpj: string;
};

interface Params {
  id: string;
}

const EditUser: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [initialData, setInitialData] = useState<IResponse | undefined>();
  const [branches, setBranches] = useState([]);
  const [carriers, setCarriers] = useState([]);
  const [carrierActive, setCarrierActive] = useState<boolean>(false);
  const [roles, setRoles] = useState<SelectProps[]>([]);
  const history = useHistory();
  const { setLoading } = useLoading();
  const { id } = useParams<Params>();
  const { addToast } = useToast();

  const getDataToApi = useCallback(async () => {
    setLoading(true);
    const { data } = await api.get(`/users/${id}`);
    setInitialData({
      ...data,
      role_id: data.role.id,
      branch_id: data.branch?.id,
      carrier_id: data.carrier?.id || null,
    });

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getRolesToApi = useCallback(async () => {
    setLoading(true);
    const response = await api.get('/roles');

    setRoles(
      response.data.data.map((role: IRoles) => {
        return {
          value: role.id,
          label: role.description,
          name: role.name,
        };
      }),
    );
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBranches = useCallback(async () => {
    setLoading(true);
    const response = await api.get('/branches');
    setBranches(
      response.data.data.map((data: Companies) => {
        return {
          value: data.id,
          label: `${data.name} - ${data.cnpj}`,
        };
      }),
    );
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCarriers = useCallback(async () => {
    setLoading(true);
    const response = await api.get('/carriers');
    setCarriers(
      response.data.data.map((data: Companies) => {
        return {
          value: data.id,
          label: `${data.name} - ${data.cnpj}`,
        };
      }),
    );
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getDataToApi();
      getBranches();
      getCarriers();
      getRolesToApi();
    }, 200);
    return () => clearTimeout(timeoutId);
  }, [getCarriers, getBranches, getDataToApi, getRolesToApi]);

  const handleSelectRole = useCallback(
    async value => {
      const filteredRole = roles.find(role => role.value === value);
      if (
        filteredRole?.name === 'carrier' ||
        filteredRole?.name === 'trucker'
      ) {
        setCarrierActive(true);
      } else {
        setCarrierActive(false);
      }
    },
    [roles],
  );

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome Obrigatório'),
          email: Yup.string().required('E-mail Obrigatório'),
          role_id: Yup.string().required('E-mail Obrigatório'),
          branch_id: data.branch_id
            ? Yup.string().required('A Empresa Obrigatória')
            : Yup.string(),
          carrier_id: data.carrier_id
            ? Yup.string().required('A Empresa Obrigatória')
            : Yup.string(),
        });

        await schema.validate(data, { abortEarly: false });

        await api.patch(`/users/${id}`, data);

        // if (data.branch_id) {
        //   // eslint-disable-next-line no-param-reassign
        //   delete data.carrier_id;
        //   await api.patch(`/users/branch/${id}`, data);
        // } else if (data.carrier_id) {
        //   // eslint-disable-next-line no-param-reassign
        //   delete data.branch_id;
        //   await api.patch(`/users/carrier/${id}`, data);
        // }

        history.push('/users');

        addToast({
          title: 'Usuario adicionado com sucesso!',
          type: 'success',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro ao atualizar o usuario',
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialData],
  );

  return (
    <Layout>
      <div className="container mx-auto">
        <h2 className="text-2xl text-opaque mt-2 mb-5">Editar Usuarios</h2>

        <div className="rounded-md shadow-md px-5 py-5 bg-white mt-5">
          <div className="my-2 ml-3">
            <span className="text-lg font-bold text-opaque">
              Dados do Usuário
            </span>
          </div>
          <hr className="mx-3" />
          <Form ref={formRef} onSubmit={handleSubmit} initialData={initialData}>
            <div className="flex flex-wrap w-full flex-row">
              <div className="p-2 w-full md:w-4/12">
                <label className="font-bold ml-1">Nome</label>
                <Input type="text" name="name" />
              </div>
              <div className="p-2 w-full md:w-4/12">
                <label className="font-bold ml-1">E-mail</label>
                <Input type="text" name="email" />
              </div>

              <div className="p-2 w-full md:w-4/12">
                <label className="font-bold ml-1">Tipo</label>
                <Select
                  name="role_id"
                  options={roles}
                  selectedValue={(value: any) => handleSelectRole(value)}
                />
              </div>
              {carrierActive === true && (
                <>
                  <div className="p-2 w-full md:w-4/12">
                    <label className="font-bold ml-1">Transportadora</label>
                    <Select name="carrier_id" options={carriers} />
                  </div>
                </>
              )}

              {carrierActive === false && (
                <div className="p-2 w-full md:w-4/12">
                  <label className="font-bold ml-1">Filial</label>
                  <Select name="branch_id" options={branches} />
                </div>
              )}
            </div>
            <div className="grid grid-cols-1 px-2">
              <div className="flex justify-end">
                <div className="w-32 mr-2">
                  <Button
                    type="submit"
                    theme="dark"
                    size="medium"
                    onClick={() => history.push('/users')}
                  >
                    Voltar
                  </Button>
                </div>

                <div className="w-32">
                  <Button
                    type="submit"
                    color="white"
                    theme="primary"
                    size="medium"
                  >
                    Salvar
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Layout>
  );
};

export default EditUser;
