import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper, { PaperProps } from '@material-ui/core/Paper';
import React from 'react';
import Draggable from 'react-draggable';

import { Button } from '../Ui';

interface DialogModalProps {
  title: string;
  description?: string;
  titleAcceptButton?: string;
  titleCancelButton?: string;
  open: boolean;
  accept: () => void;
  cancel: () => void;
}

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const DraggableDialog: React.FC<DialogModalProps> = ({
  title,
  children,
  open,
  description,
  titleAcceptButton,
  titleCancelButton,
  cancel,
  accept,
}) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={cancel}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <p>{description}</p>
          <div>{children}</div>
        </DialogContent>
        <DialogActions>
          <div className="flex w-full flex-row px-4 justify-end">
            <Button
              className="w-32"
              onClick={cancel}
              theme="dark"
              color="white"
            >
              {titleCancelButton || 'Fechar'}
            </Button>
            <Button
              className="w-32 ml-2 h-10"
              onClick={() => accept()}
              theme="primary"
              color="green"
            >
              {titleAcceptButton || 'Salvar'}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DraggableDialog;
