/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import * as Yup from 'yup';

import Layout from '../../../../../components/Layout';
import { Button, InputMask } from '../../../../../components/Ui';
import { useLoading } from '../../../../../hooks/Loading';
import { useToast } from '../../../../../hooks/Toast';
import api from '../../../../../services/api';
import getValidationErrors from '../../../../../utils/getValidationErrors';

type ICustomer = {
  name: string;
  cnpj: string;
};

type IProduct = {
  name: string;
};

type IContract = {
  reference_id: string;
};

type ICity = {
  name: string;
};

type IState = {
  name: string;
};

type IAddress = {
  neighborhood: string;
  public_place: string;
  street: string;
  complement: string;
  number: string;
  zip_code: string;
  city: ICity;
  state: IState;
};

type IBatch = {
  id: string;
  contract: IContract;
  customer_delivery: ICustomer;
  customer: ICustomer;
  product: IProduct;
  weight: string;
  kind: string;
  address: IAddress;
  guide: string;
  guide_url: string;
};

type IInitialData = {
  id: string;
  batch: IBatch;
  bid_amount: number;
  created_at: Date;
};

type FormData = {
  amount?: string;
};

interface Params {
  id: string;
}

const NewBatch: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [initialData, setInitialData] = useState<IInitialData>(
    {} as IInitialData,
  );
  const history = useHistory();
  const { setLoading } = useLoading();
  const { addToast } = useToast();
  const { id } = useParams<Params>();

  const getDataToApi = useCallback(async () => {
    setLoading(true);
    const response = await api.get(`/batches/carriers/notifications/${id}`);

    setInitialData({
      ...response.data,
      amount: new Intl.NumberFormat().format(response.data.amount),
    });

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getDataToApi();
    }, 200);
    return () => clearTimeout(timeoutId);
  }, [getDataToApi]);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          amount: Yup.string().required('Valor é Obrigatório'),
        });

        await schema.validate(data, { abortEarly: false });

        const amount = String(data.amount).slice(3, 10);

        data.amount = amount.replace('.', '').replace(/,/g, '.');

        await api.patch(`/batches/carriers/notifications/${id}`, {
          amount: data.amount,
        });

        history.push('/solicitations');

        addToast({
          title: 'Oferta enviada com com sucesso!',
          type: 'success',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro ao enviar a oferta.',
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Layout>
      <div className="container mx-auto">
        <h2 className="text-2xl text-opaque mt-2 mb-5">
          Solicitação para carregamento de lote
        </h2>

        <div className="rounded-md shadow-md px-5 py-5 bg-white mt-5">
          <div className="my-2 ml-3">
            <span className="text-lg font-bold text-opaque">Dados do lote</span>
          </div>
          <hr className="mx-3" />

          <div className="flex flex-wrap w-full flex-row px-1">
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
              <span className="text-sm font-bold">Nome: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {initialData?.batch?.customer?.name}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
              <span className="text-sm font-bold">CNPJ: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {initialData?.batch?.customer?.cnpj}
              </span>
            </div>
          </div>

          <div className="my-2 ml-3">
            <span className="text-lg font-bold text-opaque">
              Dados do Cliente para Entrega
            </span>
          </div>
          <hr className="mx-3" />
          <div className="flex flex-wrap w-full flex-row px-1">
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
              <span className="text-sm font-bold">Nome: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {initialData?.batch?.customer_delivery?.name}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
              <span className="text-sm font-bold">CNPJ: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {initialData?.batch?.customer_delivery?.cnpj}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
              <span className="text-sm font-bold">Estado: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {initialData?.batch?.address?.state?.name}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
              <span className="text-sm font-bold">Cidade: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {initialData?.batch?.address?.city?.name}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
              <span className="text-sm font-bold">Endereço: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {`${initialData?.batch?.address?.public_place} ${initialData?.batch?.address?.street}`}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
              <span className="text-sm font-bold">Bairro: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {initialData?.batch?.address?.neighborhood}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
              <span className="text-sm font-bold">Complemento: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {initialData?.batch?.address?.complement}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
              <span className="text-sm font-bold">CEP: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {initialData?.batch?.address?.zip_code}
              </span>
            </div>
          </div>
          <div className="my-2 ml-3">
            <span className="text-lg font-bold text-opaque">Produto</span>
          </div>
          <hr className="mx-3" />
          <div className="flex flex-wrap w-full flex-row px-1">
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-6/12 p-2">
              <span className="text-sm font-bold">Produto: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {initialData?.batch?.product?.name}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-6/12 p-2">
              <span className="text-sm font-bold">Quantidade: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {Number(initialData?.batch?.weight).toLocaleString('pt-BR')}
              </span>
            </div>
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-6/12 p-2">
              <span className="text-sm font-bold">Espécie: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {initialData?.batch?.kind}
              </span>
            </div>
          </div>

          <div className="my-2 ml-3">
            <span className="text-lg font-bold text-opaque">Obervação</span>
          </div>
          <hr className="mx-3" />

          <div className="flex flex-wrap w-full flex-row px-1">
            <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-6/12 p-2">
              <span className="text-sm font-bold">Roteiro: </span>
              <span className="ml-0 md:ml-1 text-sm font-light">
                {initialData?.batch?.guide}
              </span>
            </div>

            {initialData?.batch?.guide_url && (
              <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-6/12 p-2">
                <span className="text-sm font-bold">Arquivo de Roteiro: </span>
                <a
                  target="_blank"
                  href={`${initialData?.batch?.guide_url}`}
                  rel="noreferrer"
                >
                  <span className="ml-0 md:ml-1 text-sm font-light">
                    Clique aqui para baixar
                  </span>
                </a>
              </div>
            )}
          </div>

          <hr className="mx-3 mt-5" />

          <Form ref={formRef} onSubmit={handleSubmit} initialData={initialData}>
            <div className="flex flex-wrap w-full flex-row">
              <div className="p-2 w-full md:w-3/12">
                <label className="font-bold ml-1">Valor do Frete</label>
                <InputMask
                  type="text"
                  name="amount"
                  mask={createNumberMask({
                    prefix: 'R$ ',
                    thousandsSeparatorSymbol: '.',
                    allowDecimal: true,
                    decimalSymbol: ',',
                    requireDecimal: false,
                    integerLimit: 3,
                  })}
                />
              </div>
            </div>

            <div className="grid grid-cols-1 mt-5">
              <div className="flex justify-end flex-col-reverse md:flex-row">
                <div className="w-32 md:mr-2 mt-2 md:mt-0">
                  <Button
                    onClick={() => history.push('/solicitations')}
                    color="white"
                    theme="dark"
                    size="medium"
                  >
                    Voltar
                  </Button>
                </div>
                <div className="w-32">
                  <Button
                    type="submit"
                    color="white"
                    theme="primary"
                    size="medium"
                  >
                    Salvar
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Layout>
  );
};

export default NewBatch;
