import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import './styles/index.css';

import AppProvider from './hooks';
import reportWebVitals from './reportWebVitals';
import Routes from './routes';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import GlobalStyle from './styles/global';
import { defaultTheme } from './styles/theme';

ReactDOM.render(
  <ThemeProvider theme={defaultTheme}>
    <AppProvider>
      <Router>
        <Routes />
      </Router>
    </AppProvider>
    <GlobalStyle />
  </ThemeProvider>,
  document.getElementById('root'),
);

serviceWorkerRegistration.register();

reportWebVitals();
