import styled from 'styled-components';

export const TableHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;

  span {
    font-size: 20px;
  }
`;

export const Search = styled.div`
  color: #616a78;
  border: 1px solid #ebebeb;
  border-radius: 6px;
  height: 40px;
  width: 100%;
  max-width: 100%;
  padding-right: 10px;
  letter-spacing: 0.5px;
  outline: none !important;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  svg {
    margin-left: 10px;
  }
  input {
    color: #616a78;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: 300;
    width: 100%;
    border: 0;
    margin-left: 10px;
    outline: 0;
  }
`;
