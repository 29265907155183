/* eslint-disable no-nested-ternary */

export const FormaterNumberToCPFCNPJ = (value: string): string => {
  return value.length === 14
    ? value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
    : value.length === 11
    ? value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
    : value;
};

export const FormaterCPFCNPJToNumber = (value: string): number => {
  const updatedValue = value.replace(/\D/g, '') as unknown as number;

  return updatedValue;
};

export const FormaterSringToNumber = (value: string): number => {
  const updatedValue = value.replace(/\D/g, '') as unknown as number;

  return updatedValue;
};

export const FormaterStringToZipCode = (value: string): string => {
  return value.length === 8 ? value.replace(/^(\d{5})(\d{3})/, '$1-$2') : value;
};

export const FormaterStringToTellphone = (value: string): string => {
  return value.replace(/^(\d{5})(\d{3})/, '$1-$2');
};

export const FormaterDateStringToDate = (date: string): Date => {
  const pattern = /(\d{2})\/(\d{2})\/(\d{4})/;

  const dt = new Date(date.replace(pattern, '$3-$2-$1'));

  dt.setHours(dt.getHours() + 4);

  return dt;
};
export const FormaterStringToSlug = (value: string): string => {
  let str = value.replace(/^\s+|\s+$/g, '').toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = 'àáäâãèéëêìíïîòóöôõùúüûñç·/_,:;';
  const to = 'aaaaaeeeeiiiiooooouuuunc------';
  // eslint-disable-next-line no-plusplus
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str;
};
