// import Popover from '@material-ui/core/Popover';
import { useField } from '@unform/core';
import React, { InputHTMLAttributes, useEffect, useRef, useState } from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';

import { Container, Error, Popover } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  disabled?: boolean;
}

const Input: React.FC<InputProps> = ({
  name,
  icon: Icon,
  disabled,
  ...rest
}) => {
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const [isPopover, setIsPopover] = useState(false);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container
      onMouseEnter={() => setIsPopover(true)}
      onMouseLeave={() => setIsPopover(false)}
      isErrored={!!error}
      isFocused={isFocused}
      isDisabled={disabled}
    >
      {Icon && <Icon size={20} />}
      <input
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        defaultValue={defaultValue}
        disabled={disabled}
        ref={inputRef}
        {...rest}
      />

      {error && (
        <Error>
          <FiAlertCircle color="#E96379" size={20} />
        </Error>
      )}
      {isPopover && error && <Popover>{error}</Popover>}
    </Container>
  );
};

export default Input;
