import {
  FiHome,
  FiUsers,
  FiUser,
  FiChevronRight,
  FiBriefcase,
  FiPackage,
  FiTruck,
  FiFileText,
  FiSettings,
  FiMapPin,
  FiArchive,
} from 'react-icons/fi';

export const SidebarData = [
  // Home
  {
    title: 'Home',
    path: '/home',
    icon: FiHome,
    subNav: [],
    role: ['admin', 'developer', 'user', 'carrier'],
  },

  // Filiais
  {
    title: 'Cadastros',
    path: '/branches',
    icon: FiSettings,
    role: ['admin', 'developer'],
    subNav: [
      {
        title: 'Filiais',
        path: '/branches',
        icon: FiBriefcase,
        role: ['admin', 'developer'],
      },
      {
        title: 'Clientes',
        path: '/customers',
        icon: FiUsers,
        role: ['admin', 'developer', 'user'],
      },
      {
        title: 'Transportadoras',
        path: '/carriers',
        icon: FiTruck,
        role: ['admin', 'developer', 'user'],
      },
      {
        title: 'Produtos',
        path: '/products',
        icon: FiPackage,
        role: ['admin', 'developer', 'user'],
      },
    ],
  },

  // Pedidos -> Transportadoras
  {
    title: 'Fretes',
    path: '/solicitations/demand',
    icon: FiFileText,
    role: ['carrier'],
    subNav: [
      // // {
      // //   title: 'Solicitações',
      // //   path: '/solicitations',
      // //   icon: FiChevronRight,
      // //   role: ['carrier'],
      // // },
      // {
      //   title: 'Demanda',
      //   path: '/solicitations/demand',
      //   icon: FiChevronRight,
      //   role: ['carrier'],
      // },
    ],
  },
  {
    title: 'Ordens de Carregamento',
    path: '/transport-orders',
    icon: FiTruck,
    role: ['carrier'],
    subNav: [],
  },

  // Lotes
  {
    title: 'Lotes',
    path: '/batches',
    icon: FiArchive,
    role: ['admin', 'developer', 'user'],
    subNav: [
      // {
      //   title: 'Lotes Abertos',
      //   path: '/batches',
      //   icon: FiChevronRight,
      //   role: ['admin', 'developer', 'user'],
      // },
      // {
      //   title: 'Aprovar Transportadora',
      //   path: '/batches/approve',
      //   icon: FiChevronRight,
      //   role: ['admin', 'developer', 'user'],
      // },
    ],
  },

  {
    title: 'Ordens Carregamento',
    path: '#',
    icon: FiTruck,
    role: ['admin', 'developer', 'user', 'logistics_user'],
    subNav: [
      {
        title: 'Ordens de Carregamento',
        path: '/transport-orders',
        icon: FiChevronRight,
        role: ['admin', 'developer', 'user', 'logistics_user'],
      },
      {
        title: 'Iniciar Carregamento',
        path: '/transport-orders/inform-nfe',
        icon: FiChevronRight,
        role: ['admin', 'developer', 'user', 'logistics_user'],
      },
      {
        title: 'Troca de NF-e',
        path: '/transport-orders/exchange-nfe',
        icon: FiChevronRight,
        role: ['admin', 'developer', 'user', 'logistics_user'],
      },
    ],
  },
  {
    title: 'Localização',
    path: '/localization',
    icon: FiMapPin,
    subNav: [],
    role: ['admin', 'developer', 'user'],
  },

  {
    title: 'Motoristas',
    path: '/drivers',
    icon: FiUser,
    role: ['carrier'],
    subNav: [],
  },

  {
    title: 'Caminhões',
    path: '/trucks',
    icon: FiTruck,
    role: ['carrier'],
    subNav: [],
  },

  {
    title: 'Configurações',
    path: '#',
    icon: FiSettings,
    role: ['admin', 'developer'],
    subNav: [
      {
        title: 'Usuarios',
        path: '/users',
        icon: FiChevronRight,
        role: ['admin', 'developer', 'user'],
      },
      {
        title: 'Políticas de Privacidade',
        path: '/privacy-policy',
        icon: FiChevronRight,
        role: ['admin', 'developer', 'user'],
      },
    ],
  },
];
