export const defaultTheme = {
  colors: {
    primary: 'var(--color-primary)',
    secondary: 'var(--color-secondary)',
    white: 'var(--color-white)',
    gray: 'var(--color-gray)',
    'light-gray': 'var(--color-light-gray)',
    'dark-gray': 'var(--color-dark-gray)',
    opaque: 'var(--color-opaque)',
    green: 'var(--color-green)',
    blue: 'var(--color-blue)',
    red: 'var(--color-red)',
    transparent: 'transparent',
  },

  backgrounds: {
    lightest: '#f1f3f9',
    lighter: '#2f3136',
    dark: '#202225',
    darker: '#15121E',
    darkest: '#13111B',
  },
};
