/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import Layout from '../../../../components/Layout';
import {
  Button,
  Input,
  InputMask,
  Select,
  Mask,
} from '../../../../components/Ui';
import { useLoading } from '../../../../hooks/Loading';
import { useToast } from '../../../../hooks/Toast';
import api from '../../../../services/api';
import getValidationErrors from '../../../../utils/getValidationErrors';

type IResponse = {
  name: string;
  trade_name: string;
  email: string;
  cnpj: string;
  address: AddressProps;
};

type AddressProps = {
  state_id: string;
  city_id: string;
  street: string;
  phone: string;
  zip_code: string;
  latitude: string;
  longitude: string;
};

type States = {
  id: string;
  name: string;
  uf: string;
};

type Cities = {
  id: string;
  name: string;
  uf: string;
};

type SelectProps = {
  value: string;
  label: string;
  uf?: string;
};

interface Params {
  id: string;
}

const EditBranch: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [initialData, setInitialData] = useState<IResponse | undefined>();
  const [states, setStates] = useState<SelectProps[]>([]);
  const [cities, setCities] = useState<SelectProps[]>([]);
  const [loadingCities, setLoadingCities] = useState<boolean>(false);
  const history = useHistory();
  const { id } = useParams<Params>();
  const { setLoading } = useLoading();
  const { addToast } = useToast();

  const getDataToApi = useCallback(async () => {
    setLoading(true);
    const { data } = await api.get(`/branches/${id}`);

    setInitialData({
      ...data,
      address: data.address ? data.address.address : null,
    });

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStates = useCallback(async () => {
    setLoading(true);
    if (states.length <= 0) {
      const responseStates = await api.get('/states?limit=100');
      setStates(
        responseStates.data.data.map((state: States) => {
          return {
            value: state.id,
            label: state.name,
            uf: state.uf,
          };
        }),
      );
    }
    setLoading(false);
  }, [initialData]);

  const handleSelectCity = useCallback(
    async (option: any) => {
      if (option) {
        setLoadingCities(true);
        const selectedState = states.find(state => state.value === option);

        if (selectedState) {
          const filteredCities = await api.get(
            `/cities?uf=${selectedState.uf}&limit=10000`,
          );
          setCities(
            filteredCities.data.data.map((city: Cities) => {
              return {
                value: city.id,
                label: city.name,
                uf: city.uf,
              };
            }),
          );
          setLoadingCities(false);
        }
      }
    },
    [cities, states],
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getDataToApi();
      getStates();
    }, 200);
    return () => clearTimeout(timeoutId);
  }, []);

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          reference_id: Yup.string().required(
            'Codigo de referencia Obrigatório',
          ),
          name: Yup.string().required('Razão Social Obrigatória'),
          trade_name: Yup.string().required('Nome Fantasia Obrigatório'),
          cnpj: Yup.string()
            .required('CNPJ Obrigatório')
            .min(18, 'Preencha Corretamente o CNPJ'),
          address: Yup.object().shape({
            public_place: Yup.string().defined().nullable(),
            neighborhood: Yup.string().defined().nullable(),
            state_id: Yup.number().required('Estado Obrigatório'),
            city_id: Yup.number().required('Cidade Obrigatória'),
            street: Yup.string().required('Endereço Obrigatório'),
            zip_code: Yup.string().required('CEP Obrigatório'),
          }),
        });

        await schema.validate(data, { abortEarly: false });

        await api.patch(`/branches/${id}`, data);
        history.push('/branches');

        addToast({
          title: 'Filial adicionada com sucesso!',
          type: 'success',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro ao adicionar a Filial',
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Layout>
      <div className="container mx-auto">
        <h2 className="text-2xl text-opaque mt-2 mb-5">Editar uma Filial</h2>

        <div className="rounded-md shadow-md px-5 py-5 bg-white mt-5">
          <div className="my-2 ml-3">
            <span className="text-lg font-bold text-opaque">
              Dados da Filial
            </span>
          </div>
          <hr className="mx-3" />
          <Form ref={formRef} onSubmit={handleSubmit} initialData={initialData}>
            <div className="flex flex-wrap w-full flex-row">
              <div className="p-2 w-full md:w-2/12">
                <label className="font-bold ml-1">Cod. Referência</label>
                <Input type="text" name="reference_id" />
              </div>
              <div className="p-2 w-full md:w-4/12">
                <label className="font-bold ml-1">Razão Social</label>
                <Input type="text" name="name" />
              </div>

              <div className="p-2 w-full md:w-4/12">
                <label className="font-bold ml-1">Nome Fantasia</label>
                <Input type="text" name="trade_name" />
              </div>

              <div className="p-2 w-full md:w-2/12">
                <label className="font-bold ml-1">CNPJ</label>
                <InputMask mask={Mask.CNPJ} type="text" name="cnpj" />
              </div>
            </div>

            <div className="mt-6 mb-2 ml-3">
              <span className="text-lg font-bold text-opaque">Endereço</span>
            </div>
            <hr className="mx-3" />
            <div className="flex flex-wrap w-full flex-row">
              <div className="p-2 w-full md:w-3/12">
                <label className="font-bold ml-1">Logradouro</label>
                <Select
                  name="address.public_place"
                  options={[
                    { value: 'Avenida', label: 'Avenida' },
                    { value: 'Estrada', label: 'Estrada' },
                    { value: 'Rodovia', label: 'Rodovia' },
                    { value: 'Rua', label: 'Rua' },
                  ]}
                />
              </div>
              <div className="p-2 w-full sm:w-6/12 md:w-6/12">
                <label className="font-bold ml-1">Endereço</label>
                <Input type="text" name="address.street" />
              </div>
              <div className="p-2 w-full sm:w-6/12 md:w-3/12">
                <label className="font-bold ml-1">Bairro</label>
                <Input type="text" name="address.neighborhood" />
              </div>
            </div>
            <div className="flex flex-wrap w-full flex-row">
              <div className="p-2 w-full md:w-3/12">
                <label className="font-bold ml-1">CEP</label>
                <InputMask
                  mask={Mask.CEP}
                  type="text"
                  name="address.zip_code"
                />
              </div>
              <div className="p-2 w-full sm:w-6/12 md:w-4/12">
                <label className="font-bold ml-1">Estado</label>
                <Select
                  name="address.state_id"
                  options={states}
                  selectedValue={(value: any) => handleSelectCity(value)}
                />
              </div>
              <div className="p-2 w-full sm:w-6/12 md:w-4/12">
                <label className="font-bold ml-1">Cidade</label>
                <Select
                  name="address.city_id"
                  options={cities}
                  isLoading={loadingCities}
                />
              </div>
            </div>
            <div className="flex flex-wrap w-full flex-row">
              <div className="p-2 w-full sm:w-6/12 md:w-3/12">
                <label className="font-bold ml-1">Latitude</label>
                <Input type="text" name="address.latitude" />
              </div>
              <div className="p-2 w-full sm:w-6/12 md:w-3/12">
                <label className="font-bold ml-1">Longitude</label>
                <Input type="text" name="address.longitude" />
              </div>
            </div>
            <div className="grid grid-cols-1 mt-5 px-2">
              <div className="flex justify-end">
                <div className="w-32 mr-2">
                  <Button
                    type="submit"
                    theme="dark"
                    size="medium"
                    onClick={() => history.push('/branches')}
                  >
                    Voltar
                  </Button>
                </div>

                <div className="w-32">
                  <Button
                    type="submit"
                    color="primary"
                    theme="primary"
                    size="medium"
                  >
                    Salvar
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Layout>
  );
};

export default EditBranch;
