import React, { ButtonHTMLAttributes } from 'react';

import { Container } from './styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  // color: keyof typeof defaultTheme.colors;
  size?: 'small' | 'medium' | 'large';
  theme:
    | 'primary'
    | 'secondary'
    | 'dark'
    | 'light'
    | 'danger'
    | 'borded-primary'
    | 'borded-secondary'
    | 'borded-dark'
    | 'borded-light';
}

const Button: React.FC<ButtonProps> = ({
  children,
  theme,
  size,
  type = 'button',
  ...rest
}) => {
  return (
    <Container type={type} buttonTheme={theme} size={size} {...rest}>
      {children}
    </Container>
  );
};

export default Button;
