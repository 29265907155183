/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import React, { useState, useCallback, useEffect } from 'react';
import { FiSearch, FiCheckCircle } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import Layout from '../../../../components/Layout';
import { Table } from '../../../../components/Ui';
import { useLoading } from '../../../../hooks/Loading';
import api from '../../../../services/api';
import { Search } from './styles';

const PendingPage: React.FC = () => {
  const [data, setData] = useState<[]>([]);
  const history = useHistory();
  const { setLoading } = useLoading();
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [total, setTotal] = useState(0);
  const [input, setInput] = useState('');

  /**
   * Get data to api
   */
  const getDataToApi = useCallback(async () => {
    setLoading(true);
    const response = await api.get(
      `/batches/carriers/notifications?page=${page}&limit=${rowsPerPage}${
        input ? `&search=${input}` : ''
      }`,
    );

    setTotal(response.data.total);
    setRowsPerPage(response.data.per_page);
    setPage(response.data.current_page);
    setData(
      response.data.data.map((notification: any) => {
        return {
          id: notification.id,
          customer: notification.batch.customer_delivery.name,
          product: notification.batch.product.name,
          status:
            notification.carrier_answered_at === null
              ? 'Pendente'
              : notification.approved === null
              ? 'Aprovação da Empresa'
              : notification.approved === true
              ? 'Aprovado'
              : 'Recusado',
          quantity: Number(notification.batch.quantity).toLocaleString('pt-BR'),
          amount: `R$ ${new Intl.NumberFormat().format(notification.amount)}`,
          created_at: new Date(notification.created_at).toLocaleDateString(),
        };
      }),
    );
    setLoading(false);
  }, [input, rowsPerPage, page]);

  /**
   * Reload data pages and rows per page
   */
  useEffect(() => {
    const timeoutId = setTimeout(() => getDataToApi(), 200);
    return () => clearTimeout(timeoutId);
  }, [rowsPerPage, page]);

  /**
   * Reload data with input
   */
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (data.length > 0) {
      const timeoutId = setTimeout(() => getDataToApi(), 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [input]);

  /**
   * Set Input Change
   */
  const handleChangeSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setInput(value);

      if (value === '') {
        setTimeout(() => getDataToApi(), 1000);
      }
    },
    [],
  );

  /**
   * Set rows per page
   */
  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const number = event.target.value as unknown as number;
      setRowsPerPage(number);
    },
    [],
  );

  /**
   * Set page change
   */
  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage + 1);
  }, []);

  return (
    <Layout>
      <div className="container mx-auto">
        <h2 className="text-2xl text-opaque mt-2 mb-5">
          Solicitação de Carregamento Pendentes
        </h2>

        <div className="rounded-sm shadow-md px-5 py-5 bg-white mt-5">
          <div className="flex px-2 w-full flex-row">
            <Search>
              <FiSearch size={20} />
              <input
                type="text"
                name="search"
                placeholder="Filtre por produto e cliente"
                value={input}
                onChange={handleChangeSearch}
              />
            </Search>
          </div>
        </div>

        <div className="rounded-sm shadow-md px-5 py-5 bg-white mt-5">
          <span className="hidden md:flex text-lg w-full font-normal">
            Lista de Solicitações
          </span>
          <Table
            title="Listagem de Solicitações"
            columns={[
              'Cliente',
              'Produto',
              'Status',
              'Quantidade',
              'Valor',
              'Data',
            ]}
            page={page}
            rowsPerPage={rowsPerPage}
            total={total}
            setPage={handleChangePage}
            setRowsPerPage={handleChangeRowsPerPage}
            data={data}
            editable={({ id, status }) => (
              <>
                {status === 'Pendente' && (
                  <Tooltip title="Ofertar Frete" placement="top">
                    <IconButton
                      onClick={() => {
                        history.push(`/solicitations/pending/offer/${id}`);
                      }}
                    >
                      <FiCheckCircle />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
          />
        </div>
      </div>
    </Layout>
  );
};

export default PendingPage;
