import React, { createContext, useContext, useCallback, useState } from 'react';

interface LoadingContextData {
  loading: boolean;
  setLoading(load: boolean): void;
}

const LoadingContext = createContext<LoadingContextData>(
  {} as LoadingContextData,
);

export const LoadingProvider: React.FC = ({ children }) => {
  const [loading, setLoad] = useState<boolean>(false);

  const setLoading = useCallback((load: boolean) => {
    setLoad(load);
  }, []);

  return (
    <LoadingContext.Provider value={{ setLoading, loading }}>
      {children}
    </LoadingContext.Provider>
  );
};

export function useLoading(): LoadingContextData {
  const context = useContext(LoadingContext);

  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }

  return context;
}
