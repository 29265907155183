/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { FormHandles } from '@unform/core';
import React, { useState, useCallback, useEffect, createRef } from 'react';
import { FiSearch, FiInfo, FiPlus, FiEdit } from 'react-icons/fi';
import { MdAttachMoney } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

import AlterAmountFreight from '../../../components/AlterAmountFreight';
import DraggableDialog from '../../../components/DraggableDialog';
import Layout from '../../../components/Layout';
import { Table, Button } from '../../../components/Ui';
import { useLoading } from '../../../hooks/Loading';
import api from '../../../services/api';
import { Search } from './styles';

type ICustomer = {
  name: string;
};

type ICarrier = {
  name: string;
};

type IProduct = {
  name: string;
};

type IContract = {
  reference_id: string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type IRequest = {
  id: string;
  contract_reference_id: string;
  order_reference_id: string;
  contract: IContract;
  customer: ICustomer;
  delivery_customer: ICustomer;
  carrier: ICarrier;
  product: IProduct;
  balance: number;
};

const Batches: React.FC = () => {
  const [openDialogAmountFreight, setOpenDialogAmountFreight] = useState(false);
  const [data, setData] = useState<[]>([]);
  const [selectedId, setSelectedId] = useState<string | number>();
  const history = useHistory();
  const { setLoading } = useLoading();
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [total, setTotal] = useState(0);
  const [input, setInput] = useState('');
  const formRef = createRef<FormHandles>();

  /**
   * Get data to api
   */
  const getDataToApi = useCallback(async () => {
    setLoading(true);
    const response = await api.get(
      `/batches?page=${page}&limit=${rowsPerPage}${
        input ? `&search=${input}` : ''
      }`,
    );

    setTotal(response.data.total);
    setRowsPerPage(response.data.per_page);
    setPage(response.data.current_page);
    setData(
      response.data.data.map((batch: any) => {
        return {
          id: batch.id,
          contract: batch.contract_reference_id,
          order: batch.order_reference_id,
          customer: batch.delivery_customer.name,
          product: batch.product.name,
          freight: `R$ ${new Intl.NumberFormat().format(batch.amount.amount)}`,
          balance: batch?.balance.toLocaleString('pt-BR'),
        };
      }),
    );
    setLoading(false);
  }, [input, rowsPerPage, page]);

  /**
   * Reload data pages and rows per page
   */
  useEffect(() => {
    const timeoutId = setTimeout(() => getDataToApi(), 200);
    return () => clearTimeout(timeoutId);
  }, [rowsPerPage, page]);

  /**
   * Reload data with input
   */
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (data.length > 0) {
      const timeoutId = setTimeout(() => getDataToApi(), 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [input]);

  /**
   * Set Input Change
   */
  const handleChangeSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setInput(value);

      if (value === '') {
        setTimeout(() => getDataToApi(), 1000);
      }
    },
    [],
  );

  /**
   * Set rows per page
   */
  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const number = event.target.value as unknown as number;
      setRowsPerPage(number);
    },
    [],
  );

  const handleSaveAmountFreight = () => {
    formRef.current?.submitForm();
  };

  /**
   * Set page change
   */
  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage + 1);
  }, []);

  return (
    <Layout>
      <div className="container mx-auto">
        <h2 className="text-2xl text-opaque mt-2 mb-5">Lotes Abertos</h2>

        <div className="rounded-sm shadow-md px-5 py-5 bg-white mt-5">
          <div className="flex px-2 w-full flex-row">
            <Search>
              <FiSearch size={20} />
              <input
                type="text"
                name="search"
                placeholder="Filtre por contrato, nome, nome do produto ou cnpj"
                value={input}
                onChange={handleChangeSearch}
              />
            </Search>
          </div>
        </div>

        <div className="rounded-sm shadow-md px-5 py-5 bg-white mt-5">
          <div className="flex w-full items-center px-2 mb-2">
            <span className="hidden md:flex text-lg w-full font-normal">
              Listagem de lotes abertos
            </span>
            <div className="flex w-full justify-end">
              <a href="/batches/new">
                <Button type="submit" theme="primary" size="small">
                  <FiPlus size={25} />
                  Novo Lote
                </Button>
              </a>
            </div>
          </div>
          <Table
            title="Listagem de Filiais"
            columns={[
              'Contrato',
              'Pedido',
              'Cliente Entrega',
              'Produto',
              'R$ Frete',
              'KG Disponível',
            ]}
            page={page}
            rowsPerPage={rowsPerPage}
            total={total}
            setPage={handleChangePage}
            setRowsPerPage={handleChangeRowsPerPage}
            data={data}
            editable={({ id }) => (
              <div className="flex w-full">
                <Tooltip title="Editar" placement="top">
                  <IconButton
                    onClick={() => {
                      history.push(`/batches/edit/${id}`);
                    }}
                  >
                    <FiEdit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Visualizar" placement="top">
                  <IconButton
                    onClick={() => {
                      history.push(`/batches/show/${id}`);
                    }}
                  >
                    <FiInfo />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Atualizar Frete" placement="top">
                  <IconButton
                    onClick={() => {
                      setSelectedId(id);
                      setOpenDialogAmountFreight(true);
                    }}
                  >
                    <MdAttachMoney />
                  </IconButton>
                </Tooltip>

                {/* <Tooltip title="Adicionar Transportadora" placement="top">
                  <IconButton
                    onClick={() => {
                      setSelectedId(id);
                      setOpenDialogAmountFreight(true);
                    }}
                  >
                    <FiTruck />
                  </IconButton>
                </Tooltip> */}
              </div>
            )}
          />
        </div>
        {/* Modal de alterar senha */}
        <DraggableDialog
          title="Atenção"
          description="Deseja realmente alterar o valor do frete?"
          open={openDialogAmountFreight}
          titleAcceptButton="Salvar"
          titleCancelButton="Fechar"
          accept={handleSaveAmountFreight}
          cancel={() => setOpenDialogAmountFreight(false)}
        >
          <AlterAmountFreight
            ref={formRef}
            id={selectedId}
            close={setOpenDialogAmountFreight}
          />
        </DraggableDialog>
      </div>
    </Layout>
  );
};

export default Batches;
