import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, forwardRef, MutableRefObject } from 'react';
import { FiLock } from 'react-icons/fi';
import * as Yup from 'yup';

import { Input } from '../../../../components/Ui';
import { useToast } from '../../../../hooks/Toast';
import { useLoadingTopBar } from '../../../../hooks/TopLoadingBar';
import api from '../../../../services/api';
import getValidationErrors from '../../../../utils/getValidationErrors';
import { Content } from './styles';

interface PasswordDataProps {
  password: string;
  password_confirmation: string;
}

interface PasswordProps {
  id: string | number | undefined;
  close?: (data: boolean) => void;
}

const Password: React.ForwardRefRenderFunction<FormHandles, PasswordProps> = (
  { id, close },
  ref:
    | MutableRefObject<FormHandles | null>
    | ((instance: FormHandles | null) => void)
    | null,
) => {
  // const formRef = useRef<FormHandles>(ref);
  const { complete, staticStart } = useLoadingTopBar();
  const { addToast } = useToast();

  const formRef = ref as MutableRefObject<FormHandles>;

  const handleSubmit = useCallback(
    async (data: PasswordDataProps) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          password: Yup.string().min(
            6,
            'A senha deve ter no mínimo 6 caracteres',
          ),
          password_confirmation: Yup.string().oneOf(
            [Yup.ref('password'), undefined],
            'As Senhas não coincidem',
          ),
        });

        await schema.validate(data, { abortEarly: false });

        staticStart();
        await api.patch(`/users/password/${id}`, data);
        complete();
        if (close) {
          close(false);
        }

        addToast({
          type: 'success',
          title: 'Senha Atualizada com sucesso!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef?.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro ao atualizar a senha do usuario',
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id],
  );

  return (
    <Content>
      <Form onSubmit={handleSubmit} ref={formRef}>
        <div className="flex flex-wrap w-full flex-row">
          <div className="w-full md:w-6/12 p-2">
            <label className="font-bold ml-1">Senha</label>
            <Input icon={FiLock} type="password" name="password" />
          </div>
          <div className="w-full md:w-6/12 p-2">
            <label className="font-bold ml-1">Confirme a senha</label>
            <Input icon={FiLock} type="password" name="password_confirmation" />
          </div>
        </div>
      </Form>
    </Content>
  );
};

export default forwardRef(Password);
