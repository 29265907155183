/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import Layout from '../../../../components/Layout';
import {
  Button,
  Input,
  InputMask,
  Mask,
  Select,
} from '../../../../components/Ui';
import { useLoading } from '../../../../hooks/Loading';
import { useToast } from '../../../../hooks/Toast';
import api from '../../../../services/api';
import getValidationErrors from '../../../../utils/getValidationErrors';

type IResponse = {
  brand: string;
  fantasy_name: string;
  model: string;
  year_manufacture: string;
  license_plate1: string;
  license_plate2: string;
  license_plate3: string;
  license_plate4: string;
};

type FormData = {
  brand: string;
  fantasy_name: string;
  model: string;
  year_manufacture: string;
  license_plate1: string;
  license_plate2: string;
  license_plate3: string;
  license_plate4: string;
};

type States = {
  id: string;
  name: string;
  uf: string;
};

type SelectProps = {
  value: string;
  label: string;
};

interface Params {
  id: string;
}

const NewTruck: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [initialData, setInitialData] = useState<IResponse | undefined>();
  const history = useHistory();
  const { setLoading } = useLoading();
  const [states, setStates] = useState<SelectProps[]>([]);
  const { addToast } = useToast();
  const { id } = useParams<Params>();

  const getDataToApi = useCallback(async () => {
    setLoading(true);
    const { data } = await api.get(`/trucks/${id}`);

    setInitialData({
      ...data,
    });

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getStates = useCallback(async () => {
    setLoading(true);
    const responseStates = await api.get('/states?limit=100');
    setStates(
      responseStates.data.data.map((state: States) => {
        return {
          value: state.uf,
          label: state.name,
        };
      }),
    );
    setLoading(false);
  }, [setLoading]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getDataToApi();
      getStates();
    }, 100);
    return () => clearTimeout(timeoutId);
  }, [getDataToApi, getStates]);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          brand: Yup.string().required('A Fabricante Obrigatória'),
          model: Yup.string().required('O Modelo é Obrigatório'),
          year_manufacture: Yup.string().required(
            'O Ano de Fabricação é Obrigatório',
          ),
        });

        await schema.validate(data, { abortEarly: false });

        await api.patch(`/trucks/${id}`, data);
        history.push('/trucks');

        addToast({
          title: 'Caminhão adicionado com sucesso!',
          type: 'success',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro ao adicionar o Caminhão.',
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Layout>
      <div className="container mx-auto">
        <h2 className="text-2xl text-opaque mt-2 mb-5">Cadastrar Caminhão</h2>

        <div className="rounded-md shadow-md px-5 py-5 bg-white mt-5">
          <div className="my-2 ml-3">
            <span className="text-lg font-bold text-opaque">
              Dados do Caminhão
            </span>
          </div>
          <hr className="mx-3" />
          <Form ref={formRef} onSubmit={handleSubmit} initialData={initialData}>
            <div className="flex flex-wrap w-full flex-row">
              <div className="p-2 w-full md:w-3/12">
                <label className="font-bold ml-1">Fabricante</label>
                <Input type="text" name="brand" />
              </div>
              <div className="p-2 w-full md:w-3/12">
                <label className="font-bold ml-1">Modelo</label>
                <Input type="text" name="model" />
              </div>
              <div className="p-2 w-full md:w-3/12">
                <label className="font-bold ml-1">Cor</label>
                <Input type="text" name="color" />
              </div>
              <div className="p-2 w-full md:w-3/12">
                <label className="font-bold ml-1">Ano de Fabricação</label>
                <InputMask
                  type="text"
                  name="year_manufacture"
                  mask={Mask.YEAR}
                />
              </div>
            </div>
            <div className="mt-6 mb-2 ml-3">
              <span className="text-lg font-bold text-opaque">Placas</span>
            </div>
            <hr className="mx-3" />
            <div className="flex flex-wrap w-full flex-row">
              <div className="p-2 w-full md:w-3/12">
                <label className="font-bold ml-1">Placa do Cavalo</label>
                <InputMask
                  type="text"
                  name="license_plate"
                  mask={Mask.LICENSE_PLATE}
                />
              </div>
              <div className="p-2 w-full sm:w-6/12 md:w-4/12">
                <label className="font-bold ml-1">Estado</label>
                <Select name="license_plate_uf" options={states} />
              </div>
            </div>
            <div className="flex flex-wrap w-full flex-row">
              <div className="p-2 w-full md:w-3/12">
                <label className="font-bold ml-1">Placa Carreta 1</label>
                <InputMask
                  type="text"
                  name="license_plate2"
                  mask={Mask.LICENSE_PLATE}
                />
              </div>
              <div className="p-2 w-full sm:w-6/12 md:w-4/12">
                <label className="font-bold ml-1">Estado</label>
                <Select name="license_plate2_uf" options={states} />
              </div>
            </div>
            <div className="flex flex-wrap w-full flex-row">
              <div className="p-2 w-full md:w-3/12">
                <label className="font-bold ml-1">Placa Carreta 2</label>
                <InputMask
                  type="text"
                  name="license_plate3"
                  mask={Mask.LICENSE_PLATE}
                />
              </div>
              <div className="p-2 w-full sm:w-6/12 md:w-4/12">
                <label className="font-bold ml-1">Estado</label>
                <Select name="license_plate3_uf" options={states} />
              </div>
            </div>
            <div className="flex flex-wrap w-full flex-row">
              <div className="p-2 w-full md:w-3/12">
                <label className="font-bold ml-1">Placa Carreta 3</label>
                <InputMask
                  type="text"
                  name="license_plate4"
                  mask={Mask.LICENSE_PLATE}
                />
              </div>
              <div className="p-2 w-full sm:w-6/12 md:w-4/12">
                <label className="font-bold ml-1">Estado</label>
                <Select name="license_plate4_uf" options={states} />
              </div>
            </div>
            <div className="grid grid-cols-1 mt-5">
              <div className="flex justify-end">
                <div className="w-32 mr-2">
                  <Button
                    theme="dark"
                    size="medium"
                    onClick={() => history.push('/trucks')}
                  >
                    Voltar
                  </Button>
                </div>
                <div className="w-32">
                  <Button
                    type="submit"
                    color="white"
                    theme="primary"
                    size="medium"
                  >
                    Salvar
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Layout>
  );
};

export default NewTruck;
