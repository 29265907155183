import styled, { css } from 'styled-components';

export const Container = styled.header`
  grid-area: header;
  display: flex;
  width: 100%;
  height: 70px;
  /* position: absolute; */
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.theme.backgrounds.lightest};

  transition: all 0.5s ease-in-out 0s;
`;

export const LogoContent = styled.div`
  display: flex;
  width: 280px;
  height: 100%;
  object-fit: cover;
  padding: 30px 25px;
  align-items: center;
`;

export const Image = styled.img`
  width: 70%;
`;

export const UserContent = styled.div`
  display: flex;
  width: 180px;
  height: 100%;
  align-items: center;
  position: relative;
  z-index: 999;

  padding-top: 10px;
  padding-bottom: 10px;
`;

interface NotificationProps {
  active?: boolean;
}

export const Notifications = styled.div<NotificationProps>`
  display: flex;
  width: 60px;
  height: 100%;
  align-items: center;
  position: relative;
  justify-content: center;
  padding: 20px 10px;
  cursor: pointer;
  border-radius: 10px;
  opacity: 0.9;

  transition: all 100ms ease-in;

  svg {
    color: ${props => props.theme.colors.opaque};
    transition: all 100ms ease-in;
  }

  ${props =>
    props.active &&
    css`
      &::before {
        display: flex;
        content: '';
        width: 10px;
        height: 10px;
        position: absolute;
        background-color: ${props.theme.colors.red};
        border-radius: 15px;
        left: 15px;
        top: 10px;
      }
    `}

  &:hover {
    background-color: ${props => props.theme.colors.opaque};
    opacity: 1;

    svg {
      color: ${props => props.theme.colors.white};
    }
  }
`;

export const User = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  margin-left: 20px;
  padding: 20px 25px;

  span {
    color: #fff;
    font-weight: 500;
    margin-left: 15px;
    font-family: 'Roboto', sans-serif;
  }
`;

export const Avatar = styled.div`
  display: flex;
  width: 50px;
  height: 50px;
  object-fit: contain;
  /* border: 2px solid ${props => props.theme.colors.primary}; */
  cursor: pointer;
  border-radius: 50px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50px;
  }
`;

export const ToggleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  /* background-color: ${props => props.theme.colors.primary}; */
  border-radius: 50%;
  outline: none;
  margin-left: 10px;

  svg {
    color: ${props => props.theme.colors.opaque};
  }
`;
