/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { MdPerson, MdClear } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../../hooks/Authentication';
import { Container, Link } from './styles';

interface DropdownProps {
  visible: boolean;
}

type Ref = HTMLDivElement;

const Header = React.forwardRef<Ref, DropdownProps>(({ visible }, ref) => {
  const { signOut } = useAuth();

  const history = useHistory();

  return (
    <Container ref={ref} visible={visible}>
      <Link onClick={() => history.push('/profile')}>
        <span>Perfil</span>
        <MdPerson size={20} />
      </Link>
      <Link onClick={() => signOut()}>
        <span>Sair da Plataforma</span>
        <MdClear size={20} />
      </Link>
    </Container>
  );
});

export default Header;
