/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useRef, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import Layout from '../../../../components/Layout';
import { Button, Input, InputMask, Mask } from '../../../../components/Ui';
import { useLoading } from '../../../../hooks/Loading';
import { useToast } from '../../../../hooks/Toast';
import api from '../../../../services/api';
import getValidationErrors from '../../../../utils/getValidationErrors';

type FormData = {
  brand: string;
  fantasy_name: string;
  model: string;
  year_manufacture: string;
  license_plate1: string;
  license_plate2: string;
  license_plate3: string;
  license_plate4: string;
};

type IUser = {
  name: string;
};

type IResponse = {
  id: string;
  user: IUser;
  cpf: string;
  rg: string;
  email: string;
  driver_license: string;
};

interface Params {
  id: string;
}

const EditTrucker: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [initialData, setInitialData] = useState<IResponse | undefined>();
  const history = useHistory();
  const { setLoading } = useLoading();
  const { addToast } = useToast();
  const { id } = useParams<Params>();

  const getDataToApi = useCallback(async () => {
    setLoading(true);
    const { data } = await api.get(`/drivers/${id}`);

    setInitialData({
      ...data,
    });

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getDataToApi();
    }, 100);
    return () => clearTimeout(timeoutId);
  }, [getDataToApi]);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('O Nome Obrigatório'),
          cpf: Yup.string().required('O CPF é Obrigatório'),
          driver_license: Yup.string().required(
            'O Numero da Carteira de motorista é Obrigatório',
          ),
          phone: Yup.string().required('Telefone para contato é Obrigatório'),
        });

        await schema.validate(data, { abortEarly: false });

        await api.patch(`/drivers/${id}`, data);
        history.push('/drivers');

        addToast({
          title: 'Motorista atualizado com sucesso!',
          type: 'success',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro ao atualizar o Motorista.',
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Layout>
      <div className="container mx-auto">
        <h2 className="text-2xl text-opaque mt-2 mb-5">Cadastrar Caminhão</h2>

        <div className="rounded-md shadow-md px-5 py-5 bg-white mt-5">
          <div className="my-2 ml-3">
            <span className="text-lg font-bold text-opaque">
              Dados do Caminhão
            </span>
          </div>
          <hr className="mx-3" />
          <Form ref={formRef} onSubmit={handleSubmit} initialData={initialData}>
            <div className="flex flex-wrap w-full flex-row">
              <div className="p-2 w-full md:w-4/12">
                <label className="font-bold ml-1">Nome</label>
                <Input type="text" name="name" />
              </div>
              <div className="p-2 w-full md:w-4/12">
                <label className="font-bold ml-1">CPF</label>
                <InputMask type="text" name="cpf" mask={Mask.CPF} />
              </div>
              <div className="p-2 w-full md:w-4/12">
                <label className="font-bold ml-1">Carteira de Motorista</label>
                <Input type="text" name="driver_license" />
              </div>
              <div className="p-2 w-full md:w-4/12">
                <label className="font-bold ml-1">Telefone</label>
                <InputMask type="text" name="phone" mask={Mask.CELLPHONE} />
              </div>
            </div>
            <div className="grid grid-cols-1 mt-5">
              <div className="flex justify-end">
                <div className="w-32 mr-2">
                  <Button
                    theme="dark"
                    size="medium"
                    onClick={() => history.push('/drivers')}
                  >
                    Voltar
                  </Button>
                </div>
                <div className="w-32">
                  <Button
                    type="submit"
                    color="white"
                    theme="primary"
                    size="medium"
                  >
                    Salvar
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Layout>
  );
};

export default EditTrucker;
