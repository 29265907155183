import React, { useState, useCallback, useEffect } from 'react';

import Content from './Content';
import Header from './Header';
import Sidebar from './Sidebar';
import { Container } from './styles';

const Layout: React.FC = ({ children }) => {
  const [sidebarActive, setSidebarActive] = useState<boolean>(() => {
    const active = localStorage.getItem('@Lacus:sidebar');

    if (active) {
      return JSON.parse(active);
    }

    return true;
  });

  const getWindowDimensions = useCallback(() => {
    const { innerWidth: width, innerHeight: height } = window;

    return {
      width,
      height,
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [getWindowDimensions]);

  useEffect(() => {
    const { width } = getWindowDimensions();

    if (width <= 991.98) {
      localStorage.setItem('@Lacus:sidebar', JSON.stringify(false));
      setSidebarActive(false);
    }
  }, [getWindowDimensions]);

  const handleActiveSidebarHeader = useCallback(() => {
    localStorage.setItem('@Lacus:sidebar', JSON.stringify(!sidebarActive));
    setSidebarActive(!sidebarActive);
  }, [sidebarActive]);

  const handleActiveSidebarContent = useCallback(() => {
    const { width } = getWindowDimensions();
    if (sidebarActive === true && width <= 991.98) {
      localStorage.setItem('@Lacus:sidebar', JSON.stringify(false));
      setSidebarActive(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebarActive]);

  return (
    <Container>
      <Header setActiveSidebar={handleActiveSidebarHeader} />
      <Sidebar active={sidebarActive} />
      <Content
        setActiveHeader={handleActiveSidebarContent}
        headerActive={sidebarActive}
      >
        {children}
      </Content>
    </Container>
  );
};

export default Layout;
