import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';

import api from '../services/api';

interface AuthState {
  token: string;
  user: UserProps;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface UserProps {
  name: string;
  email: string;
  avatar_url: string;
  role: string;
  id: number;
}

interface AuthContextData {
  user: UserProps;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  updateData(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@Bertuol:token');
    const user = localStorage.getItem('@Bertuol:user');

    api.defaults.headers.common = { Authorization: `Bearer ${token}` };

    if (token && user) {
      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const checkAuth = useCallback(async () => {
    try {
      await api.get('/authentication');
    } catch (err) {
      signOut();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateData = useCallback(async () => {
    try {
      const response = await api.get('/users/me');
      const token = localStorage.getItem('@Bertuol:token');

      localStorage.setItem('@Bertuol:user', JSON.stringify(response.data));

      if (token) {
        setData({ token, user: response.data });
      }
    } catch (err) {
      signOut();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data.token) {
      checkAuth();
    }
  }, [checkAuth, data]);

  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post('/authentication', {
      email,
      password,
    });

    const { token, user } = response.data;

    localStorage.setItem('@Bertuol:token', token);
    localStorage.setItem('@Bertuol:user', JSON.stringify(user));

    api.defaults.headers.common = { Authorization: `bearer ${token}` };

    setData({ token, user });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@Bertuol:token');
    localStorage.removeItem('@Bertuol:user');

    api.defaults.headers.common = { Authorization: null };

    setData({} as AuthState);
  }, []);

  return (
    <AuthContext.Provider
      value={{ user: data.user, signIn, signOut, updateData }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
