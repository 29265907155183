import { useField } from '@unform/core';
import React, { InputHTMLAttributes, useEffect, useRef, useState } from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import InputMask from 'react-text-mask';

import { Container, Error, Popover } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  borderTheme?: 'rounded' | 'rounded-dark' | 'rounded-light';
  boxShadow?: boolean;
  mask: Array<string | RegExp>;
}

const Input: React.FC<InputProps> = ({
  name,
  icon: Icon,
  borderTheme,
  boxShadow,
  mask,
  ...rest
}) => {
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const [isPopover, setIsPopover] = useState(false);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: ref => {
        return ref.inputElement.value ? ref.inputElement.value : '';
      },
      setValue: (ref, value) => {
        // eslint-disable-next-line no-param-reassign
        ref.inputElement.value = value;
      },
      clearValue: ref => {
        // eslint-disable-next-line no-param-reassign
        ref.inputElement.value = '';
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container
      isErrored={!!error}
      isFocused={isFocused}
      borderTheme={borderTheme}
      boxShadow={boxShadow}
      onMouseEnter={() => setIsPopover(true)}
      onMouseLeave={() => setIsPopover(false)}
    >
      {Icon && <Icon size={20} />}
      <InputMask
        mask={mask}
        guide={false}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        defaultValue={defaultValue}
        ref={inputRef}
        {...rest}
      />

      {error && (
        <Error>
          <FiAlertCircle color="#E96379" size={20} />
        </Error>
      )}
      {isPopover && error && <Popover>{error}</Popover>}
    </Container>
  );
};

export default Input;
