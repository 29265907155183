import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import { useAuth } from '../hooks/Authentication';
import Home from '../pages/Home';
import Login from '../pages/Login';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import Profile from '../pages/Profile';
import ValidateOrder from '../pages/ValidateOrder';
import Admin from './admin.routes';
import Carrier from './carrier.routes';
import RouteAuth from './Route';

const Routes: React.FC = () => {
  const { user } = useAuth();
  return (
    <Switch>
      <RouteAuth path="/" exact component={Login} />
      <RouteAuth path="/home" exact component={Home} isPrivate />
      <RouteAuth path="/profile" exact component={Profile} isPrivate />

      <Route path="/validate/order/:id" exact component={ValidateOrder} />

      <Route path="/politicas-de-privacidade" exact component={PrivacyPolicy} />
      {/** Admin Pages */}
      {user && user.role !== 'carrier' && <Admin />}
      {/** Carrier Pages */}
      {user && user.role === 'carrier' && <Carrier />}
      <Redirect from="*" to="/" />
    </Switch>
  );
};

export default Routes;
