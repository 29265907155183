import React from 'react';

import Layout from '../../components/Layout';
import { useAuth } from '../../hooks/Authentication';

const Home: React.FC = () => {
  const { user } = useAuth();
  return (
    <Layout>
      <div className="container mx-auto">
        <h2 className="text-2xl text-opaque mt-2 mb-5">
          {`Seja Bem Vindo `}
          <strong className="font-bold">{user.name}</strong>
        </h2>
      </div>
    </Layout>
  );
};

export default Home;
