import styled from 'styled-components';

export const Container = styled.div`
  .demo-wrapper {
    overflow: hidden;
  }
  .demo-editor {
    height: 300px;
    overflow: scroll;
  }
`;
