import styled, { css } from 'styled-components';

import { defaultTheme } from '../../../styles/theme';

interface ContainerProps {
  isFocused: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  background-color: #fff;
  border-radius: 6px;
  padding: 0 16px 0 16px;
  width: 100%;

  display: flex;
  align-items: center;

  /* border: 1px solid ${defaultTheme.backgrounds.lighter}; */
  border: 2px solid transparent;
  color: #666360;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.2);

  & + div {
    margin-top: 8px;
  }

  ${props =>
    props.isFocused &&
    css`
      color: #000;
      /* color: ${defaultTheme.colors.green}; */
      border: 2px solid ${defaultTheme.colors.green};
      /* border-color: ${defaultTheme.colors.green}; */
      /* border-width: 1px; */
    `}

  ${props =>
    props.isErrored &&
    css`
      /* color: ${defaultTheme.colors.red}; */
      color: #000;
      border: 2px solid ${defaultTheme.colors.red};
      /* border-color: ${defaultTheme.colors.red}; */
      /* border-width: 1px; */
    `}

  input {
    font-size: 15px;
    flex: 1;
    background: transparent;
    color: #000;
    border: 0;
    padding: 8px 0 8px 0;

    &::placeholder {
      color: #777777;
    }

    &:focus {
      outline: 0;
      box-shadow: none;
    }

    &:hover {
      border: 0;
    }
  }

  svg {
    margin-right: 15px;
  }
`;

export const Error = styled.div`
  height: 20px;
  margin-left: 15px;
  svg {
    margin: 0;
  }
`;
