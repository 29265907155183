import styled from 'styled-components';

export const Container = styled.div`
  padding: 10px;
  overflow-x: auto;

  table {
    svg {
      cursor: pointer;
      width: 14px;
      height: 14px;
      color: #929daf;

      &:hover {
        color: var(--color-dark);
      }
      &.color-success {
        color: var(--color-green);
      }
      &.color-danger {
        color: var(--color-red);
      }
    }

    &.table-actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding-right: 15px;

      svg {
        &.danger {
          color: var(--color-red);
        }
        &.success {
          color: var(--color-green);
        }
        &.primary {
          color: var(--color-primary);
        }
        &.secondary {
          color: var(--color-secondary);
        }
      }
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;
