import styled from 'styled-components';

import { defaultTheme } from '../../../styles/theme';

export const Container = styled.div`
  cursor: pointer;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;

  &.accept {
    border-color: ${defaultTheme.colors.green};
    color: ${defaultTheme.colors.green};
  }

  &.reject {
    border-color: ${defaultTheme.colors.red};
    color: ${defaultTheme.colors.red};
  }
`;
