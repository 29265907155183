/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import * as Yup from 'yup';

import Layout from '../../components/Layout';
import { Button, Input, Dropzone } from '../../components/Ui';
import { useAuth } from '../../hooks/Authentication';
import { useLoading } from '../../hooks/Loading';
import { useToast } from '../../hooks/Toast';
import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';
import AlterPassword from '../Admin/Users/AlterPassword';

type IResponse = {
  id: string;
  name: string;
  email: string;
  username: string;
  created_at?: string;
};

const Profile: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const formPasswordRef = useRef<FormHandles>(null);
  const [initialData, setInitialData] = useState<IResponse | undefined>();
  const { setLoading } = useLoading();
  const { addToast } = useToast();
  const { user, updateData } = useAuth();

  const getDataToApi = useCallback(async () => {
    setLoading(true);
    const { data } = await api.get(`/user`);
    setInitialData(data);

    setLoading(false);
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getDataToApi();
    }, 200);
    return () => clearTimeout(timeoutId);
  }, []);

  const handleSaveAlterPassword = () => {
    formPasswordRef.current?.submitForm();
  };

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome Obrigatório'),
          email: Yup.string().required('E-mail Obrigatório'),
        });

        await schema.validate(data, { abortEarly: false });

        await api.patch(`/user`, data);

        addToast({
          title: 'Usuario Atualizado com sucesso!',
          type: 'success',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro ao atualizar o usuario.',
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialData],
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = async (formData: any) => {
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: (event: { loaded: number; total: number }) => {
        console.log(
          `Current progress:`,
          Math.round((event.loaded * 100) / event.total),
        );
      },
    };

    await api
      .patch('/users/avatar', formData, config)
      .then(() => {
        updateData();
        addToast({
          type: 'success',
          title: 'Avatar Atualizado com sucesso!',
        });
      })
      .catch(err => {
        console.log(err);
        addToast({
          type: 'error',
          title: 'Não foi possivel atualizar o avatar!',
        });
      });
  };

  const handleDropZone = useCallback(acceptedFiles => {
    const filteredFile = acceptedFiles[0];
    const formData = new FormData();

    formData.append('avatar', filteredFile);

    onChange(formData);
  }, []);

  return (
    <Layout>
      <div className="container mx-auto">
        <h2 className="text-2xl text-opaque mt-2 mb-5">Perfil</h2>
        <div className="rounded-md shadow-md px-5 py-5 bg-white mt-5">
          <div className="my-2 ml-3">
            <span className="text-lg font-bold text-opaque">
              Dados do Usuário
            </span>
          </div>
          <hr className="mx-3" />
          <Form ref={formRef} onSubmit={handleSubmit} initialData={initialData}>
            <div className="flex flex-wrap w-full flex-row">
              <div className="p-2 w-full md:w-6/12">
                <label className="font-bold ml-1">Nome</label>
                <Input type="text" name="name" />
              </div>
              <div className="p-2 w-full md:w-6/12">
                <label className="font-bold ml-1">E-mail</label>
                <Input type="text" name="email" disabled />
              </div>
            </div>
            <div className="grid grid-cols-1">
              <div className="flex justify-end">
                <div className="w-32">
                  <Button
                    type="submit"
                    color="white"
                    theme="primary"
                    size="medium"
                  >
                    Salvar
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
        <div className="rounded-md shadow-md px-5 py-5 bg-white mt-5">
          <div className="my-2 ml-3">
            <span className="text-lg font-bold text-opaque">Alterar Senha</span>
          </div>
          <hr className="mx-3" />
          <div className="px-2">
            <AlterPassword ref={formPasswordRef} id={user.id} />
            <div className="grid grid-cols-1">
              <div className="flex justify-end">
                <div className="w-32">
                  <Button
                    type="button"
                    onClick={() => handleSaveAlterPassword()}
                    color="white"
                    theme="primary"
                    size="medium"
                  >
                    Salvar
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rounded-md shadow-md px-5 py-5 bg-white mt-5">
          <div className="my-2 ml-3">
            <span className="text-lg font-bold text-opaque">
              Avatar de Perfil
            </span>
          </div>
          <hr className="mx-3" />
          <Dropzone
            accept="image/*"
            onDrop={handleDropZone}
            maxSize={2000000}
            multiple={false}
            isAccept="Solte aqui seu arquivo"
            isReject="Esse formato de aquivo não é aceito"
            isActive="Arraste e solte ou clique para adicionar"
          />
        </div>
      </div>
    </Layout>
  );
};

export default Profile;
