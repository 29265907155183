import styled, { css } from 'styled-components';

interface DropdownProps {
  visible: boolean;
}

export const Container = styled.div<DropdownProps>`
  width: 256px;
  height: auto;
  background-color: ${props => props.theme.colors.opaque};
  position: absolute;
  top: calc(100% + 25px);
  right: 0px;
  border-radius: 6px;

  box-shadow: rgb(0 0 0 / 60%) 0px 5px 20px;
  transition: opacity 0.2s ease 0s, visibility 0.2s ease 0s;
  opacity: 0;
  visibility: hidden;

  ${props =>
    props.visible &&
    css`
      opacity: 1;
      visibility: visible;
    `}

  &::before {
    content: '';
    position: absolute;
    top: -8px;
    right: 19px;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0px 8px 8px;
    border-color: transparent transparent ${props => props.theme.colors.opaque};
  }
`;

export const Link = styled.a`
  display: flex;
  flex-direction: row-reverse;
  -webkit-box-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  align-items: center;
  font-size: 16px;
  color: rgb(225, 225, 230);
  padding: 12px 24px;
  transition: background 0.2s ease 0s;
  cursor: pointer;
  background: transparent;
  border: none;
  width: 100%;
  text-decoration: none;

  span {
    margin-left: 24px;
  }

  svg {
    /* color: ${props => props.theme.colors.primary}; */
  }

  &:hover {
    background: rgba(0, 0, 0, 0.125);
  }
`;
