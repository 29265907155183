/* eslint-disable react-hooks/exhaustive-deps */
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { FormHandles } from '@unform/core';
import React, { useState, useCallback, useEffect, createRef } from 'react';
import { FiSearch, FiInfo, FiEdit, FiPlus, FiLock } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import DraggableDialog from '../../../components/DraggableDialog';
import Layout from '../../../components/Layout';
import { Table, Button } from '../../../components/Ui';
import { useLoading } from '../../../hooks/Loading';
import api from '../../../services/api';
import AlterPassword from './AlterPassword';
import { Search } from './styles';

type IRequest = {
  id: string;
  name: string;
  email: string;
  cnpj: string;
  active: string;
};

const Carriers: React.FC = () => {
  const [data, setData] = useState<IRequest[]>([]);
  const [openDialogPassword, setOpenDialogPassword] = useState(false);
  const [selectedId, setSelectedId] = useState<string | number>();
  const formRef = createRef<FormHandles>();
  const history = useHistory();
  const { setLoading } = useLoading();
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [total, setTotal] = useState(0);
  const [input, setInput] = useState('');

  /**
   * Get data to api
   */
  const getDataToApi = useCallback(async () => {
    setLoading(true);
    const response = await api.get(
      `/carriers?page=${page}&limit=${rowsPerPage}${
        input ? `&search=${input}` : ''
      }`,
    );

    setTotal(response.data.total);
    setRowsPerPage(response.data.per_page);
    setPage(response.data.current_page);
    setData(
      response.data.data.map((carrier: IRequest) => {
        return {
          id: carrier.id,
          nome: carrier.name,
          cnpj: carrier.cnpj,
          email: carrier.email,
        };
      }),
    );
    setLoading(false);
  }, [input, rowsPerPage, page]);

  /**
   * Reload data pages and rows per page
   */
  useEffect(() => {
    const timeoutId = setTimeout(() => getDataToApi(), 200);
    return () => clearTimeout(timeoutId);
  }, [rowsPerPage, page]);

  /**
   * Reload data with input
   */
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (data.length > 0) {
      const timeoutId = setTimeout(() => getDataToApi(), 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [input]);

  /**
   * Set Input Change
   */
  const handleChangeSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setInput(value);

      if (value === '') {
        setTimeout(() => getDataToApi(), 1000);
      }
    },
    [],
  );

  /**
   * Set rows per page
   */
  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const number = event.target.value as unknown as number;
      setRowsPerPage(number);
    },
    [],
  );

  const handleSaveAlterPassword = () => {
    formRef.current?.submitForm();
  };

  /**
   * Set page change
   */
  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage + 1);
  }, []);

  return (
    <Layout>
      <div className="container mx-auto">
        <div className="flex flex-row justify-between items-center px-2 mb-4 mt-2">
          <h2 className="text-2xl text-opaque">Transportadoras</h2>
        </div>

        <div className="rounded-sm shadow-md px-5 py-5 bg-white mt-5">
          <div className="flex px-2 w-full flex-row">
            <Search>
              <FiSearch size={20} />
              <input
                type="text"
                name="search"
                placeholder="Filtre por nome e cnpj"
                value={input}
                onChange={handleChangeSearch}
              />
            </Search>
          </div>
        </div>

        <div className="rounded-sm shadow-md px-5 py-5 bg-white mt-5">
          <div className="flex w-full items-center px-2 mb-2">
            <span className="hidden md:flex text-lg w-full font-normal">
              Listagem de transportadoras
            </span>
            <div className="flex w-full justify-end">
              <a href="/carriers/new">
                <Button type="submit" theme="primary" size="small">
                  <FiPlus size={25} />
                  Nova transportadora
                </Button>
              </a>
            </div>
          </div>
          <Table
            title="Listagem de Filiais"
            columns={['Nome', 'CNPJ']}
            page={page}
            rowsPerPage={rowsPerPage}
            total={total}
            setPage={handleChangePage}
            setRowsPerPage={handleChangeRowsPerPage}
            data={data}
            editable={({ id }) => (
              <div className="flex w-full">
                <Tooltip title="Alterar senha" placement="top">
                  <IconButton
                    onClick={() => {
                      setSelectedId(id);
                      setOpenDialogPassword(true);
                    }}
                  >
                    <FiLock />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Visualizar" placement="top">
                  <IconButton
                    onClick={() => {
                      history.push(`/carriers/show/${id}`);
                    }}
                  >
                    <FiInfo />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Editar" placement="top">
                  <IconButton
                    onClick={() => {
                      history.push(`/carriers/edit/${id}`);
                    }}
                  >
                    <FiEdit />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          />
        </div>
        {/* Modal de alterar senha */}
        <DraggableDialog
          title="Atenção"
          description="Deseja realmente alterar a senha deste usuario?"
          open={openDialogPassword}
          titleAcceptButton="Salvar"
          titleCancelButton="Fechar"
          accept={handleSaveAlterPassword}
          cancel={() => setOpenDialogPassword(false)}
        >
          <AlterPassword
            ref={formRef}
            id={selectedId}
            close={setOpenDialogPassword}
          />
        </DraggableDialog>
      </div>
    </Layout>
  );
};

export default Carriers;
