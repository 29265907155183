/* eslint-disable react-hooks/exhaustive-deps */
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import React, { useState, useCallback, useEffect } from 'react';
import { FiSearch, FiEdit, FiPlus } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import Layout from '../../../components/Layout';
import { Table, Button } from '../../../components/Ui';
import { useLoading } from '../../../hooks/Loading';
import api from '../../../services/api';
import {
  FormaterNumberToCPFCNPJ,
  FormaterStringToTellphone,
} from '../../../utils/ConvertDataTypes';
import { Search } from './styles';

type IRequest = {
  id: string;
  name: string;
  email: string;
  cnpj: string;
  active: string;
};

const Truckers: React.FC = () => {
  const [data, setData] = useState<IRequest[]>([]);
  const history = useHistory();
  const { setLoading } = useLoading();
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [total, setTotal] = useState(0);
  const [input, setInput] = useState('');

  /**
   * Get data to api
   */
  const getDataToApi = useCallback(async () => {
    setLoading(true);
    const response = await api.get(
      `/drivers?page=${page}&limit=${rowsPerPage}${
        input ? `&search=${input}` : ''
      }`,
    );

    setTotal(response.data.total);
    setRowsPerPage(response.data.per_page);
    setPage(response.data.current_page);
    setData(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      response.data.data.map((driver: any) => {
        return {
          id: driver.id,
          name: driver.name,
          phone: FormaterStringToTellphone(driver.phone),
          cpf: FormaterNumberToCPFCNPJ(driver.cpf),
        };
      }),
    );
    setLoading(false);
  }, [input, rowsPerPage, page]);

  /**
   * Reload data pages and rows per page
   */
  useEffect(() => {
    const timeoutId = setTimeout(() => getDataToApi(), 200);
    return () => clearTimeout(timeoutId);
  }, [rowsPerPage, page]);

  /**
   * Reload data with input
   */
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (data.length > 0) {
      const timeoutId = setTimeout(() => getDataToApi(), 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [input]);

  /**
   * Set Input Change
   */
  const handleChangeSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setInput(value);

      if (value === '') {
        setTimeout(() => getDataToApi(), 1000);
      }
    },
    [],
  );

  /**
   * Set rows per page
   */
  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const number = event.target.value as unknown as number;
      setRowsPerPage(number);
    },
    [],
  );

  /**
   * Set page change
   */
  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage + 1);
  }, []);

  return (
    <Layout>
      <div className="container mx-auto">
        <div className="flex flex-row justify-between items-center px-2 mb-4 mt-2">
          <h2 className="text-2xl text-opaque">Motoristas</h2>
        </div>

        <div className="rounded-sm shadow-md px-5 py-5 bg-white mt-5">
          <div className="flex px-2 w-full flex-row">
            <Search>
              <FiSearch size={20} />
              <input
                type="text"
                name="search"
                placeholder="Filtre por Nome, CPF ou Carteira de Habilitação"
                value={input}
                onChange={handleChangeSearch}
              />
            </Search>
          </div>
        </div>

        <div className="rounded-sm shadow-md px-5 py-5 bg-white mt-5">
          <div className="flex w-full items-center px-2 mb-2">
            <span className="hidden md:flex text-lg w-full font-normal">
              Listagem de Caminhões
            </span>
            <div className="flex w-full justify-end">
              <a href="/drivers/new">
                <Button type="submit" theme="primary" size="small">
                  <FiPlus size={25} />
                  Novo Motorista
                </Button>
              </a>
            </div>
          </div>
          <Table
            title="Listagem de Filiais"
            columns={['Nome', 'Telefone', 'CPF']}
            page={page}
            rowsPerPage={rowsPerPage}
            total={total}
            setPage={handleChangePage}
            setRowsPerPage={handleChangeRowsPerPage}
            data={data}
            editable={({ id }) => (
              <>
                <Tooltip title="Editar" placement="top">
                  <IconButton
                    onClick={() => {
                      history.push(`/drivers/edit/${id}`);
                    }}
                  >
                    <FiEdit />
                  </IconButton>
                </Tooltip>
              </>
            )}
          />
        </div>
      </div>
    </Layout>
  );
};

export default Truckers;
