/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { useDropzone, DropzoneProps } from 'react-dropzone';

import { Container, Content } from './styles';

interface DropzoneDefaultProps extends DropzoneProps {
  isActive: string;
  isReject?: string;
  isAccept?: string;
}

const DropZone: React.FC<DropzoneDefaultProps> = ({
  onDrop,
  isActive,
  isReject,
  isAccept,
  ...rest
}) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    ...rest,
    onDrop,
  });

  return (
    <Container>
      <Content
        className={`${isDragReject && 'reject'} ${isDragActive && 'accept'}`}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {isDragAccept && <p>{isAccept}</p>}
        {isDragReject && <p>{isReject}</p>}
        {!isDragActive && <p>{isActive}</p>}
      </Content>
    </Container>
  );
};

export default DropZone;
