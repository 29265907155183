import { animated } from 'react-spring';
import styled, { css } from 'styled-components';

import { defaultTheme } from '../../styles/theme';

interface ContainerProps {
  type?: 'success' | 'error' | 'info';
}

const toastTypeVariations = {
  info: css`
    background: #ebf8ff;
    color: #3172b7;
  `,

  success: css`
    background: ${defaultTheme.colors.green};
    color: ${defaultTheme.colors.white};
  `,

  error: css`
    background: ${defaultTheme.colors.red};
    color: ${defaultTheme.colors.white};
  `,
};

export const Container = styled(animated.div)<ContainerProps>`
  width: 320px;
  position: relative;
  padding: 16px 30px 16px 16px;
  margin: 0 24px 8px 0;
  border-radius: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  & + div {
    margin-top: 8px;
  }
  ${props => toastTypeVariations[props.type || 'info']}
  &:first-child {
    margin-top: 24px;
  }
  > svg {
    margin: 3px 12px 0 0;
  }
  div {
    flex: 1;
    margin-right: 16px;
    strong {
      font-size: 14px;
    }
    p {
      margin-top: 4px;
      font-size: 14px;
      opacity: 0.8;
      line-height: 18px;
    }
  }
  button {
    position: absolute;
    right: 8px;
    top: 18px;
    /* opacity: 0.6; */
    border: 0;
    background: transparent;
    color: inherit;
    cursor: pointer;
    transition: opacity 0.2s;
    &:houver {
      opacity: 1;
    }
    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }
`;
