/* eslint-disable import/no-unresolved */
/* eslint-disable react-hooks/exhaustive-deps */
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import React, { useState, useCallback } from 'react';
import { Editor } from 'react-draft-wysiwyg';

// import { useHistory } from 'react-router-dom';
// import { FiSearch } from 'react-icons/fi';
import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import Layout from '../../../../components/Layout';
import { Button } from '../../../../components/Ui';
import api from '../../../../services/api';
// import { useLoading } from '../../../../hooks/Loading';
import { Container } from './styles';

const Settings: React.FC = () => {
  const [editor, setEditor] = useState<EditorState>();
  // const history = useHistory();

  const onEditorStateChange = useCallback(editorState => {
    setEditor(editorState);
  }, []);

  const onSubmitChange = useCallback(async () => {
    if (editor) {
      const html = draftToHtml(convertToRaw(editor.getCurrentContent()));

      await api.post('/privacy-policies', { html });
    }
  }, [editor]);

  return (
    <Layout>
      <Container>
        <div className="container mx-auto">
          <div className="flex flex-row justify-between items-center px-2 mb-4 mt-2">
            <h2 className="text-2xl text-opaque">Políticas de Privacidade</h2>
          </div>

          <div className="rounded-sm shadow-md px-5 py-5 bg-white mt-5 h-auto">
            <div className="block">
              <Editor
                editorState={editor}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={onEditorStateChange}
              />
            </div>
            <div className="grid grid-cols-1 mt-5">
              <div className="flex justify-end">
                <div className="w-32 ml-4">
                  <Button
                    onClick={() => onSubmitChange()}
                    type="submit"
                    color="white"
                    theme="primary"
                    size="medium"
                  >
                    Salvar
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default Settings;
