/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useState, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import Layout from '../../../../components/Layout';
import { Button, Input, Select } from '../../../../components/Ui';
import { useToast } from '../../../../hooks/Toast';
import api from '../../../../services/api';
import getValidationErrors from '../../../../utils/getValidationErrors';

type ICarrier = {
  id: string;
  name: string;
};

type IUser = {
  id: string;
  name: string;
};

type IClient = {
  id: string;
  name: string;
  cnpj: string;
  address: IAddress;
};

type IProduct = {
  id: string;
  name: string;
};

type ITrucker = {
  id: string;
  name: string;
  user: IUser;
};

type ITruck = {
  id: string;
  brand: string;
  model: string;
  year_manufacture?: string;
  license_plate: string;
  license_plate2: string;
  license_plate3: string;
  license_plate4: string;
};

type ICity = {
  name: string;
};

type IState = {
  name: string;
};

type IAddress = {
  neighborhood: string;
  public_place: string;
  street: string;
  complement: string;
  number: string;
  zip_code: string;
  city: ICity;
  state: IState;
};

type FormData = {
  reference_id?: string;
  id?: string;
  invoice?: string;
  invoice2?: string;
  invoices: string[];
  weight: string;
  auxiliary_invoice?: string;
};

type IBatch = {
  id: string;
  customer: IClient;
  address: IAddress;
  product: IProduct;
  delivery_customer: IClient;
  weight: number;
};

type IOrder = {
  id: string;
  reference_id: string;
  truck: ITruck;
  carrier: ICarrier;
  driver: ITrucker;
  nfe2_transfer: string;
  batch: IBatch;
  weight: string;
};

const StartOrder: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [order, setOrder] = useState<IOrder>({} as IOrder);
  const [nfeAuxiliary, setNfeAuxiliary] = useState<boolean>(false);
  const history = useHistory();
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        formRef.current?.setErrors({});

        delete data.auxiliary_invoice;

        if (data.invoice) {
          data.invoices = [];
          data.invoices.push(data.invoice);
          delete data.invoice;
        }

        if (data.invoice2) {
          data.invoices.push(data.invoice2);
          delete data.invoice2;
        }

        const schema = Yup.object().shape({
          invoices: Yup.array().required('A Nota Fiscal Obrigatória'),
        });

        await schema.validate(data, { abortEarly: false });

        delete data.reference_id;

        await api.patch(
          `/transport-orders/invoices/transfer/${order.id}`,
          data,
        );

        history.push('/transport-orders');

        addToast({
          title: 'Ordem de transporte atualizada com sucesso!',
          type: 'success',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro ao atualizar a ordem de transporte.',
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [order],
  );

  const handleGetOrderData = useCallback(async () => {
    try {
      const reference_id = formRef.current?.getFieldValue('reference_id');
      const response = await api.get(
        `/transport-orders?reference=${reference_id}`,
      );

      if (!response.data.data[0]) {
        throw new Error('Ordem de transporte não encontrada!');
      }

      setOrder(response.data.data[0]);
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Ordem de transporte não encontrada!',
      });
    }
  }, [addToast]);

  const handleClear = useCallback(() => {
    setOrder({} as IOrder);
    formRef.current?.setFieldValue('reference_id', '');
  }, []);

  return (
    <Layout>
      <div className="container mx-auto">
        <h2 className="text-2xl text-opaque mt-2 mb-5">
          Troca de NF-e da Ordem de Transporte
        </h2>

        <div className="rounded-md shadow-md px-5 py-5 bg-white mt-5">
          <div className="my-2 ml-3">
            <span className="text-lg font-bold text-opaque">
              Informe os dados abaixo
            </span>
          </div>

          <hr className="mx-3" />
          <Form ref={formRef} onSubmit={handleSubmit}>
            <div className="flex flex-wrap w-full flex-row">
              <div className="p-2 w-full md:w-3/12">
                <label className="font-bold ml-1">Numero da Ordem</label>
                <Input
                  type="number"
                  name="reference_id"
                  onBlur={() => handleGetOrderData()}
                  onKeyPress={e =>
                    e.key === 'Enter' ? handleGetOrderData() : () => false
                  }
                />
              </div>

              {order.id && (
                <>
                  <div className="p-2 w-full md:w-3/12">
                    <label className="font-bold ml-1">N.º NFE</label>
                    <Input type="number" name="invoice" />
                  </div>

                  <div className="p-2 w-full md:w-3/12">
                    <label className="font-bold ml-1">
                      Possui NFE Auxiliar?
                    </label>
                    <Select
                      name="auxiliary_invoice"
                      selectedValue={value => setNfeAuxiliary(value)}
                      options={[
                        { value: true, label: 'Sim' },
                        { value: false, label: 'Não' },
                      ]}
                    />
                  </div>
                  {nfeAuxiliary && (
                    <div className="p-2 w-full md:w-3/12">
                      <label className="font-bold ml-1">N.º NFE 2</label>
                      <Input type="number" name="invoice2" />
                    </div>
                  )}
                </>
              )}
            </div>

            {order.id && (
              <>
                <div className="flex flex-wrap w-full flex-col">
                  <div className="my-2 ml-3">
                    <span className="text-lg font-bold text-opaque">
                      Cliente para Entrega
                    </span>
                  </div>
                  <hr className="mx-3" />
                  <div className="flex flex-wrap w-full flex-row px-1">
                    <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
                      <span className="text-sm font-bold">Nome: </span>
                      <span className="ml-0 md:ml-1 text-sm font-light">
                        {order?.batch?.delivery_customer?.name}
                      </span>
                    </div>
                    <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
                      <span className="text-sm font-bold">CNPJ: </span>
                      <span className="ml-0 md:ml-1 text-sm font-light">
                        {order?.batch?.delivery_customer?.cnpj}
                      </span>
                    </div>
                    <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
                      <span className="text-sm font-bold">Estado: </span>
                      <span className="ml-0 md:ml-1 text-sm font-light">
                        {order?.batch?.address?.state?.name}
                      </span>
                    </div>
                    <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
                      <span className="text-sm font-bold">Cidade: </span>
                      <span className="ml-0 md:ml-1 text-sm font-light">
                        {order?.batch?.address?.city?.name}
                      </span>
                    </div>
                    <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-8/12 p-2">
                      <span className="text-sm font-bold">Endereço: </span>
                      <span className="ml-0 md:ml-1 text-sm font-light">
                        {`${order?.batch?.address?.public_place || ''} ${
                          order?.batch?.address?.street || ''
                        } ${order?.batch?.address?.number || ''}`}
                      </span>
                    </div>
                    <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
                      <span className="text-sm font-bold">Bairro: </span>
                      <span className="ml-0 md:ml-1 text-sm font-light">
                        {order?.batch?.address?.neighborhood}
                      </span>
                    </div>
                    <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
                      <span className="text-sm font-bold">Complemento: </span>
                      <span className="ml-0 md:ml-1 text-sm font-light">
                        {order?.batch?.address?.complement}
                      </span>
                    </div>
                    <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
                      <span className="text-sm font-bold">CEP: </span>
                      <span className="ml-0 md:ml-1 text-sm font-light">
                        {order?.batch?.address?.zip_code}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap w-full flex-col">
                  <div className="my-2 ml-3">
                    <span className="text-lg font-bold text-opaque">
                      Produto
                    </span>
                  </div>
                  <hr className="mx-3" />
                  <div className="flex flex-wrap w-full flex-row px-1">
                    <div className="flex flex-col md:flex-row w-full p-2">
                      <span className="text-sm font-bold">Produto: </span>
                      <span className="ml-0 md:ml-1 text-sm font-light">
                        {order?.batch?.product?.name}
                      </span>
                    </div>
                    <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
                      <span className="text-sm font-bold">
                        Quantidade Prevista:
                      </span>
                      <span className="ml-0 md:ml-1 text-sm font-light">
                        {Number(order?.batch?.weight || 0).toLocaleString(
                          'pt-BR',
                        ) || ''}
                      </span>
                    </div>

                    <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
                      <span className="text-sm font-bold">
                        Quantidade Agendada:
                      </span>
                      <span className="ml-0 md:ml-1 text-sm font-light">
                        {Number(order?.weight || 0).toLocaleString('pt-BR')}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="flex flex-wrap w-full flex-col">
                  <div className="my-2 ml-3">
                    <span className="text-lg font-bold text-opaque">
                      Dados da Transportadora
                    </span>
                  </div>
                  <hr className="mx-3" />
                  <div className="flex flex-wrap w-full flex-row px-1">
                    <div className="flex flex-col md:flex-row w-full p-2">
                      <span className="text-sm font-bold">Transportadora:</span>
                      <span className="ml-0 md:ml-1 text-sm font-light">
                        {order?.carrier?.name}
                      </span>
                    </div>
                    <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
                      <span className="text-sm font-bold">Motorista:</span>
                      <span className="ml-0 md:ml-1 text-sm font-light">
                        {order?.driver?.name}
                      </span>
                    </div>

                    <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-8/12 p-2">
                      <span className="text-sm font-bold">Caminhão:</span>
                      <span className="ml-0 md:ml-1 text-sm font-light">
                        {`${order?.truck?.brand || ''} - ${
                          order?.truck?.model || ''
                        }`}
                      </span>
                    </div>

                    <div className="flex flex-col md:flex-row w-full sm:w-6/12 md:w-4/12 p-2">
                      <span className="text-sm font-bold">Placas:</span>
                      {order?.truck?.license_plate && (
                        <span className="ml-0 md:ml-1 text-sm font-light">
                          {`${order?.truck?.license_plate}`}
                        </span>
                      )}
                      {order?.truck?.license_plate2 && (
                        <span className="ml-0 md:ml-1 text-sm font-light">
                          {`, ${order?.truck?.license_plate2}`}
                        </span>
                      )}
                      {order?.truck?.license_plate3 && (
                        <span className="ml-0 md:ml-1 text-sm font-light">
                          {`, ${order?.truck?.license_plate3}`}
                        </span>
                      )}
                      {order?.truck?.license_plate4 && (
                        <span className="ml-0 md:ml-1 text-sm font-light">
                          {`, ${order?.truck?.license_plate4}`}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="grid grid-cols-1 mt-5">
              <div className="flex justify-end">
                <div className="w-32">
                  <Button
                    onClick={() => handleClear()}
                    color="white"
                    theme="dark"
                    size="medium"
                  >
                    Voltar
                  </Button>
                </div>
                <div className="w-32 ml-4">
                  <Button
                    disabled={!order.id}
                    type="submit"
                    color="white"
                    theme="primary"
                    size="medium"
                  >
                    Salvar
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Layout>
  );
};

export default StartOrder;
